<template>
	<div v-if="modoVisualizacao == 'mobile'">
		<img src="@/assets/images/background-2.png" class="imagem-fundo" />

		<a-spin :spinning="loading">
			<template #indicator>
				<div
					style="
						display: flex;
						justify-content: center;
						align-items: center;
					"
				>
					<img
						src="@/assets/images/carregamento.gif"
						style="width: 150px; height: auto"
					/>
				</div>
			</template>

			<div id="conteudo-pagina">
				<div>PARA OS NOIVOS!</div>

				<div
					id="area-recados"
					ref="areaRecados"
					v-if="recados.length > 0"
				>
					<div class="recado" v-for="recado in recados" :key="recado">
						<div class="nome font-montserrat">
							{{ recado.nome }}
						</div>
						<a-typography-paragraph
							class="mensagem"
							:ellipsis="
								true
									? {
											rows: 4,
											expandable: true,
											symbol: 'abrir',
									  }
									: false
							"
							:content="recado.mensagem"
						/>
					</div>
				</div>

				<div id="area-recados" ref="areaRecados" v-else>
					<p style="margin-top: 100px">Nenhum recado ainda...</p>
				</div>

				<div id="area-botao">
					<button class="botao" @click="visible = true">
						DEIXE SEU RECADO
					</button>
				</div>
			</div>
		</a-spin>
	</div>

	<div v-else>
		<div v-if="modoVisualizacao == 'desktop'">
			<a-drawer
				placement="left"
				:visible="visibleMenu"
				@close="visibleMenu = false"
				class="desktop-menu"
				width="20%"
			>
				<template #closeIcon>
					<MenuOutlined id="icone-fechar-menu" class="texto-branco" />
				</template>

				<template #extra>
					<a-button
						style="margin-right: 8px"
						@click="irParaAdmin()"
						v-if="usuarioAutenticado.perfil == 'ADMIN'"
					>
						Admin
					</a-button>
				</template>

				<nav id="menu-navegacao">
					<router-link
						:to="{ name: 'home' }"
						class="lista-item"
						@click="visibleMenu = false"
					>
						<div class="texto-branco lista-item font-custom">
							<span class="item-menu"> TELA INICIAL </span>
							<RightOutlined class="icone-menu" />
						</div>
					</router-link>

					<hr class="separador-menu-desktop" />

					<router-link
						:to="{ name: 'convite' }"
						class="lista-item"
						@click="visibleMenu = false"
					>
						<div class="texto-branco lista-item font-custom">
							<span class="item-menu"> O CONVITE </span>
							<RightOutlined class="icone-menu" />
						</div>
					</router-link>

					<hr class="separador-menu-desktop" />

					<router-link
						:to="{ name: 'detalhes' }"
						class="lista-item"
						@click="visibleMenu = false"
					>
						<div class="texto-branco lista-item font-custom">
							<span class="item-menu"> O CASAMENTO </span>
							<RightOutlined class="icone-menu" />
						</div>
					</router-link>

					<hr class="separador-menu-desktop" />

					<router-link
						:to="{ name: 'confirmacao' }"
						class="lista-item"
						@click="visibleMenu = false"
					>
						<div class="texto-branco lista-item font-custom">
							<span class="item-menu"> CONFIRMAR PRESENÇA </span>
							<RightOutlined class="icone-menu" />
						</div>
					</router-link>

					<hr class="separador-menu-desktop" />

					<router-link
						:to="{ name: 'recados' }"
						class="lista-item"
						@click="visibleMenu = false"
					>
						<div class="texto-branco lista-item font-custom">
							<span class="item-menu"> DEIXE SEU RECADO </span>
							<RightOutlined class="icone-menu" />
						</div>
					</router-link>

					<hr class="separador-menu-desktop" />

					<router-link
						:to="{ name: 'presentes' }"
						class="lista-item"
						@click="visibleMenu = false"
					>
						<div class="texto-branco lista-item-presente">
							DESEJA NOS PRESENTEAR ?
						</div>
					</router-link>

					<div @click="sair()" id="area-sair">
						<span class="texto-branco font-custom"> SAIR </span>
					</div>
				</nav>

				<div style="text-align: center">
					<img
						src="@/assets/images/logo-dj.png"
						:style="
							modoVisualizacao == 'mobile'
								? 'width: 80%'
								: 'width: 50%'
						"
					/>
				</div>
			</a-drawer>
			<div id="area-botao-menu-desktop">
				<a-button
					shape="circle"
					:id="
						modoVisualizacao == 'mobile'
							? 'botao-menu'
							: 'botao-menu-desktop'
					"
					@click="visibleMenu = true"
				>
					<MenuOutlined />
				</a-button>

				<div id="barra-menu-desktop"></div>

				<div id="titulo-pagina-desktop">
					<div id="titulo-1" class="font-montserrat">
						{{ tituloPagina()[0] }}
					</div>
					<div id="titulo-2">{{ tituloPagina()[1] }}</div>
				</div>
			</div>
		</div>

		<img src="@/assets/images/fundo-desktop.png" class="imagem-fundo" />

		<a-spin :spinning="loading">
			<template #indicator>
				<div
					style="
						display: flex;
						justify-content: center;
						align-items: center;
					"
				>
					<img
						src="@/assets/images/carregamento.gif"
						style="width: 150px; height: auto"
					/>
				</div>
			</template>

			<div id="conteudo-pagina-desktop">
				<div
					id="area-recados-dektop"
					ref="areaRecados"
					v-if="recados.length > 0"
				>
					<div
						class="recado-desktop"
						v-for="recado in recados"
						:key="recado"
					>
						<div class="nome font-montserrat">
							{{ recado.nome }}
						</div>
						<a-typography-paragraph
							class="mensagem"
							:ellipsis="
								true
									? {
											rows: 4,
											expandable: true,
											symbol: 'abrir',
									  }
									: false
							"
							:content="recado.mensagem"
						/>
					</div>
				</div>

				<div id="area-recados-desktop" ref="areaRecados" v-else>
					<p style="margin-top: 100px">Nenhum recado ainda...</p>
				</div>

				<div id="area-botao">
					<button class="botao" @click="visible = true">
						DEIXE SEU RECADO
					</button>
				</div>
			</div>
		</a-spin>
	</div>

	<a-modal
		v-model:visible="visible"
		title="Deixe seu recado"
		centered
		@ok="modal = false"
	>
		<template #footer>
			<a-button
				@click="enviarRecado()"
				style="
					border-radius: 6px;
					background-color: #c2a6c1;
					border-color: #c2a6c1;
					color: #fff;
				"
				:loading="loading"
			>
				Enviar
			</a-button>
		</template>

		<div>
			<a-select
				v-model:value="novoRecado.nome"
				style="width: 100%"
				placeholder="Selecione seu nome"
				:status="erroNome ? 'error' : ''"
			>
				<template
					v-for="convidado in usuarioAutenticado.convidados"
					:key="convidado.id"
				>
					<a-select-option :value="convidado.nome">
						{{ convidado.nome }}
					</a-select-option>
				</template>
			</a-select>

			<a-typography-text
				type="danger"
				v-if="erroNome"
				style="margin-left: 5px"
			>
				selecione seu nome
			</a-typography-text>

			<a-textarea
				v-model:value="novoRecado.mensagem"
				show-count
				:rows="5"
				:maxlength="300"
				placeholder="Digite seu recado"
				style="margin-top: 10px"
			/>
		</div>
	</a-modal>
</template>

<script>
import { MenuOutlined, RightOutlined } from "@ant-design/icons-vue";
import Menu from "@/components/MenuComponent.vue";
import API from "@/api";

export default {
	components: {
		MenuOutlined,
		RightOutlined,
		Menu,
	},
	data() {
		return {
			modoVisualizacao: "",
			erroNome: false,
			visible: false,
			visibleMenu: false,
			windowHeight: 0,
			windowWidth: 0,
			recados: [],
			loading: false,
			novoRecado: {
				nome: null,
				mensagem: "",
			},
		};
	},

	computed: {
		usuarioAutenticado() {
			return this.$store.getters.getUser;
		},
	},

	watch: {
		"novoRecado.nome"(value) {
			if (value) {
				this.erroNome = false;
			}
		},

		windowWidth(val) {
			this.modoVisualizacao =
				this.windowHeight > this.windowWidth ? "mobile" : "desktop";
		},
	},

	created() {
		window.addEventListener("resize", this.resizeHandler);
	},

	destroyed() {
		window.removeEventListener("resize", this.resizeHandler);
	},

	mounted() {
		this.windowHeight = window.innerHeight;
		this.windowWidth = window.innerWidth;

		this.listarRecados();
	},

	methods: {
		irParaAdmin() {
			this.$router.push({ name: "homeAdmin" });
		},

		sair() {
			this.$store.dispatch("logout");
			this.$router.push({ name: "entrar" });
		},

		resizeHandler(e) {
			this.windowHeight = window.innerHeight;
			this.windowWidth = window.innerWidth;
		},

		listarRecados() {
			this.loading = true;

			API.listarRecadosAprovados()
				.then((response) => {
					this.recados = response.data;
					this.loading = false;
				})
				.catch(() => {
					this.loading = false;
				});
		},

		enviarRecado() {
			this.loading = true;

			if (!this.novoRecado.nome) {
				this.erroNome = true;
				this.loading = false;
				return;
			}

			const recado = {
				nome: this.novoRecado.nome,
				mensagem: this.novoRecado.mensagem,
			};

			API.adicionarRecado(recado)
				.then(() => {
					this.visible = false;
					this.novoRecado = {
						mensagem: "",
						nome: null,
					};

					this.erroNome = false;
					this.loading = false;

					this.$message.success("Obrigado por deixar um recado!");
				})
				.catch(() => {
					this.loading = false;
				});
		},

		tituloPagina() {
			switch (this.$route.name) {
				case "home":
					return ["", ""];
				case "detalhes":
					return ["CASAMENTO", "DETALHES"];
				case "recados":
					return ["DEIXE SEU", "RECADO"];
				case "confirmacao":
					return ["CONFIRME", "PRESENÇA"];
				case "presentes":
					return ["LISTA DE", "PRESENTES"];
				case "confirmarPresenca":
					return ["CONFIRMAR", "PRESENÇA"];
				default:
					return ["", ""];
			}
		},
	},
};
</script>

<style lang="less" scoped>
#conteudo-pagina {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: -5%;
	padding: 15px;
}

#conteudo-pagina-desktop {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 15px;
}

#area-recados {
	margin-top: 20px;
	width: 100%;
	height: 50vh;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	overflow: auto;
	padding: 0 15px 15px 15px;
}

#area-recados-dektop {
	margin-top: 20px;
	width: 50%;
	height: 50vh;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	overflow: auto;
	padding: 0 15px 15px 15px;
}
.recado {
	width: 100%;
	padding: 10px 10px 10px 15px;
	margin-bottom: 10px;
	border-radius: 12px;
	background-color: #fcf1f7b2;

	//corte o texto que não couber

	.nome {
		color: #999595;
		font-style: italic;
	}

	.mensagem {
		color: #737373;
	}
}

.recado-desktop {
	width: 100%;
	padding: 10px 10px 10px 15px;
	margin-bottom: 10px;
	border-radius: 12px;
	background-color: #fcf1f7b2;

	//corte o texto que não couber

	.nome {
		color: #999595;
		font-style: italic;
		font-size: 18px;
	}

	.mensagem {
		color: #737373;
		font-size: 16px;
	}
}

#area-botao {
	margin-top: 20px;
}

.botao {
	border-radius: 24px;
	padding: 10px 20px;
	font-size: 11px;
	line-height: 22px;
	background-color: #fdd6e7;
	color: #644f44;
	border: none;
	cursor: pointer;
}

*::-webkit-scrollbar {
	width: 12px;
}

*::-webkit-scrollbar-track {
	background: #fcf1f7;
}

*::-webkit-scrollbar-thumb {
	background-color: #f2c3dc;
	border-radius: 20px;
	border: 3px solid #f1f1f1;
}

.lista-item {
	width: 100%;
	font-weight: bolder;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	.item-menu {
		font-size: 1.3em;
		width: 70%;
		line-height: 1em;
		cursor: pointer;
		padding: 10px 20px;
	}

	.icone-menu {
		color: #6b604e;
		font-weight: bolder;
	}
}

#area-sair {
	padding-top: 20px;
	cursor: pointer;
	user-select: none;
	font-size: 2em;
}

.separador-menu-desktop {
	border: none;
}

.lista-item-presente {
	padding-top: 10px;
	font-size: 10px;
	font-weight: 400;
	letter-spacing: 1px;
}

#titulo-pagina-desktop {
	margin-left: 10px;

	#titulo-1 {
		font-size: 17px;
		line-height: 0px;
		color: #fff;
		letter-spacing: 1.5px;
	}

	#titulo-2 {
		font-size: 42px;
		line-height: 50px;
		font-weight: bold;
		color: #fff;
	}
}
</style>
