export default ({ instance }) => {
	return {
		listaConvidadosConfirmados() {
			return instance({
				method: "get",
				url: "convidado/total-confirmados",
			});
		},

		listaTitulares() {
			return instance({
				method: "get",
				url: "titular",
			});
		},

		titularPorId(id) {
			return instance({
				method: "get",
				url: "titular/" + id,
			});
		},

		adicionarConvidado(convidado) {
			return instance({
				method: "post",
				url: "convidado",
				data: convidado,
			});
		},

		adicionarTitular(titular) {
			return instance({
				method: "post",
				url: "titular",
				data: titular,
			});
		},

		editarConvidado(convidado) {
			return instance({
				method: "put",
				url: "convidado",
				data: convidado,
			});
		},

		confirmarOuDesconfirmarConvidado(convidado) {
			return instance({
				method: "put",
				url: "convidado/confirmar-desconfirmar",
				data: convidado,
			});
		},

		editarTitular(titular) {
			return instance({
				method: "put",
				url: "titular",
				data: titular,
			});
		},

		conviteCriadoTitular(id) {
			return instance({
				method: "post",
				url: "titular/marcar-convite-criado/" + id,
			});
		},

		conviteRemovidoTitular(id) {
			return instance({
				method: "post",
				url: "titular/desmarcar-convite-criado/" + id,
			});
		},

		excluirConvidado(id) {
			return instance({
				method: "delete",
				url: "convidado/" + id,
			});
		},

		excluirTitular(id) {
			return instance({
				method: "delete",
				url: "titular/" + id,
			});
		},

		quantidadeConvidadosAdultosTotal() {
			return instance({
				method: "get",
				url: "convidado/quantidade-adultos-total",
			});
		},

		quantidadeConvidadosCriancasTotal() {
			return instance({
				method: "get",
				url: "convidado/quantidade-criancas-total",
			});
		},

		quantidadeConvidadosAdultosConfirmados() {
			return instance({
				method: "get",
				url: "convidado/quantidade-adultos-confirmados",
			});
		},

		quantidadeConvidadosCriancasConfirmados() {
			return instance({
				method: "get",
				url: "convidado/quantidade-criancas-confirmados",
			});
		},
	};
};
