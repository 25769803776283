<template>
	<a-back-top />
	<div v-if="modoVisualizacao == 'mobile'">
		<a-drawer
			placement="left"
			:visible="visible"
			@close="visible = false"
			class="menu"
			width="60%"
		>
			<template #closeIcon>
				<MenuOutlined id="icone-fechar-menu" class="texto-branco" />
			</template>

			<template #extra>
				<a-button
					style="margin-right: 8px"
					@click="irParaAdmin()"
					v-if="usuarioAutenticado.perfil == 'ADMIN'"
				>
					Admin
				</a-button>
			</template>

			<nav id="menu-navegacao">
				<router-link
					:to="{ name: 'home' }"
					class="lista-item"
					@click="visible = false"
				>
					<div class="texto-branco lista-item font-custom">
						<span class="item-menu"> TELA INICIAL </span>
						<RightOutlined class="icone-menu" />
					</div>
				</router-link>

				<hr class="separador-menu" />

				<router-link
					:to="{ name: 'convite' }"
					class="lista-item"
					@click="visible = false"
				>
					<div class="texto-branco lista-item font-custom">
						<span class="item-menu"> O CONVITE </span>
						<RightOutlined class="icone-menu" />
					</div>
				</router-link>

				<hr class="separador-menu" />

				<router-link
					:to="{ name: 'detalhes' }"
					class="lista-item"
					@click="visible = false"
				>
					<div class="texto-branco lista-item font-custom">
						<span class="item-menu"> O CASAMENTO </span>
						<RightOutlined class="icone-menu" />
					</div>
				</router-link>

				<hr class="separador-menu" />

				<router-link
					:to="{ name: 'confirmacao' }"
					class="lista-item"
					@click="visible = false"
				>
					<div class="texto-branco lista-item font-custom">
						<span class="item-menu"> CONFIRMAR PRESENÇA </span>
						<RightOutlined class="icone-menu" />
					</div>
				</router-link>

				<hr class="separador-menu" />

				<router-link
					:to="{ name: 'recados' }"
					class="lista-item"
					@click="visible = false"
				>
					<div class="texto-branco lista-item font-custom">
						<span class="item-menu"> DEIXE SEU RECADO </span>
						<RightOutlined class="icone-menu" />
					</div>
				</router-link>

				<hr class="separador-menu" />

				<router-link
					:to="{ name: 'presentes' }"
					class="lista-item"
					@click="visible = false"
				>
					<div class="texto-branco lista-item-presente">
						DESEJA NOS PRESENTEAR ?
					</div>
				</router-link>

				<div @click="sair()" id="area-sair">
					<span class="texto-branco font-custom"> SAIR </span>
				</div>
			</nav>

			<div style="text-align: center">
				<img src="@/assets/images/logo-dj.png" style="width: 80%" />
			</div>
		</a-drawer>

		<div id="area-botao-menu-presentes">
			<div>
				<a-button
					shape="circle"
					id="botao-menu-presentes"
					@click="visible = true"
				>
					<MenuOutlined />
				</a-button>

				<div id="titulo-pagina-presentes">
					<div id="titulo-1-presentes">LISTA DE</div>
					<div id="titulo-2-presentes">PRESENTES</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else>
		<a-drawer
			placement="left"
			:visible="visible"
			@close="visible = false"
			class="desktop-menu"
			width="20%"
		>
			<template #closeIcon>
				<MenuOutlined id="icone-fechar-menu" class="texto-branco" />
			</template>

			<template #extra>
				<a-button
					style="margin-right: 8px"
					@click="irParaAdmin()"
					v-if="usuarioAutenticado.perfil == 'ADMIN'"
				>
					Admin
				</a-button>
			</template>

			<nav id="menu-navegacao">
				<router-link
					:to="{ name: 'home' }"
					class="lista-item"
					@click="visible = false"
				>
					<div class="texto-branco lista-item font-custom">
						<span class="item-menu"> TELA INICIAL </span>
						<RightOutlined class="icone-menu" />
					</div>
				</router-link>

				<hr class="separador-menu-desktop" />

				<router-link
					:to="{ name: 'convite' }"
					class="lista-item"
					@click="visible = false"
				>
					<div class="texto-branco lista-item font-custom">
						<span class="item-menu"> O CONVITE </span>
						<RightOutlined class="icone-menu" />
					</div>
				</router-link>

				<hr class="separador-menu-desktop" />

				<router-link
					:to="{ name: 'detalhes' }"
					class="lista-item"
					@click="visible = false"
				>
					<div class="texto-branco lista-item font-custom">
						<span class="item-menu"> O CASAMENTO </span>
						<RightOutlined class="icone-menu" />
					</div>
				</router-link>

				<hr class="separador-menu-desktop" />

				<router-link
					:to="{ name: 'confirmacao' }"
					class="lista-item"
					@click="visible = false"
				>
					<div class="texto-branco lista-item font-custom">
						<span class="item-menu"> CONFIRMAR PRESENÇA </span>
						<RightOutlined class="icone-menu" />
					</div>
				</router-link>

				<hr class="separador-menu-desktop" />

				<router-link
					:to="{ name: 'recados' }"
					class="lista-item"
					@click="visible = false"
				>
					<div class="texto-branco lista-item font-custom">
						<span class="item-menu"> DEIXE SEU RECADO </span>
						<RightOutlined class="icone-menu" />
					</div>
				</router-link>

				<hr class="separador-menu-desktop" />

				<router-link
					:to="{ name: 'presentes' }"
					class="lista-item"
					@click="visible = false"
				>
					<div class="texto-branco lista-item-presente">
						DESEJA NOS PRESENTEAR ?
					</div>
				</router-link>

				<div @click="sair()" id="area-sair">
					<span class="texto-branco font-custom"> SAIR </span>
				</div>
			</nav>

			<div style="text-align: center">
				<img
					src="@/assets/images/logo-dj.png"
					:style="
						modoVisualizacao == 'mobile'
							? 'width: 80%'
							: 'width: 50%'
					"
				/>
			</div>
		</a-drawer>
		<div id="area-botao-menu-desktop">
			<a-button
				shape="circle"
				:id="
					modoVisualizacao == 'mobile'
						? 'botao-menu'
						: 'botao-menu-desktop'
				"
				@click="visible = true"
			>
				<MenuOutlined />
			</a-button>

			<div id="barra-menu-desktop"></div>

			<div id="titulo-pagina-desktop">
				<div id="titulo-1" class="font-montserrat">LISTA DE</div>
				<div id="titulo-2">PRESENTES</div>
			</div>
		</div>
	</div>

	<a-spin :spinning="loading">
		<template #indicator>
			<div
				style="
					display: flex;
					justify-content: center;
					align-items: center;
					margin-top: 20%;
				"
			>
				<img
					src="@/assets/images/carregamento.gif"
					style="width: 150px; height: auto"
				/>
			</div>
		</template>

		<div id="conteudo-presente">
			<div id="area-querido-convidado">
				<img
					src="@/assets/images/querido-convidado.png"
					id="querido-convidado"
				/>
			</div>

			<div
				:id="
					modoVisualizacao == 'mobile'
						? 'area-texto'
						: 'area-texto-desktop'
				"
			>
				<p class="font-custom">
					Sua presença são o maior presente que poderíamos receber,
					entendemos que alguns de vocês podem desejar nos presentear
					nesta ocasião especial.
				</p>

				<p class="font-custom">
					Para aqueles que desejarem nos presentear, criamos uma lista
					de presentes online, com sugestões que nos ajudarão a
					iniciar nossa jornada juntos.
				</p>
			</div>

			<div id="area-logo">
				<img src="@/assets/images/logo-jd-rosa.png" id="logo-jd" />
			</div>

			<div
				:id="
					modoVisualizacao == 'mobile'
						? 'area-filtros'
						: 'area-filtros-desktop'
				"
			>
				<a-row type="flex" justify="space-between" align="center">
					<a-col>
						<div id="area-categorias">
							<div
								:class="
									categoriaSelecionada == 'TODOS'
										? 'tag-categoria-selecionada'
										: 'tag-categoria'
								"
								@click="categoriaSelecionada = 'TODOS'"
							>
								<AppstoreOutlined /> TODOS
							</div>

							<div
								v-for="categoria in categorias"
								:key="categoria.id"
								:class="
									categoriaSelecionada ==
									categoria.nome.toUpperCase()
										? 'tag-categoria-selecionada'
										: 'tag-categoria'
								"
								@click="
									categoriaSelecionada =
										categoria.nome.toUpperCase()
								"
							>
								<IconeCategoria
									:icone="categoria.icone.toUpperCase()"
								/>
								{{ categoria.nome.toUpperCase() }}
							</div>
						</div>
					</a-col>

					<a-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
						<div id="area-ordenacao">
							<a-select
								v-model:value="ordenacao"
								style="width: 100%"
							>
								<a-select-option value="relevancia">
									Relevância
								</a-select-option>
								<a-select-option value="menor-valor">
									Menor Valor
								</a-select-option>
								<a-select-option value="maior-valor">
									Maior Valor
								</a-select-option>
								<a-select-option value="az">
									A-Z
								</a-select-option>
								<a-select-option value="za">
									Z-A
								</a-select-option>
							</a-select>
						</div>
					</a-col>
				</a-row>
			</div>

			<div
				:id="
					modoVisualizacao == 'mobile'
						? 'area-produtos'
						: 'area-produtos-desktop'
				"
			>
				<a-row
					type="flex"
					justify="space-between"
					align="center"
					style="padding: 0 6px"
				>
					<a-col
						:xs="12"
						:sm="12"
						:md="12"
						:lg="6"
						:xl="6"
						v-for="presente in listaPresentesComFiltro()"
						:key="presente.id"
					>
						<div
							:class="
								presente.disponivel
									? 'card-presente'
									: 'card-presente-vendido'
							"
						>
							<img
								class="imagem-presente"
								:src="presente.imagem"
								alt=""
							/>
							<div class="nome-presente">{{ presente.nome }}</div>
							<div class="valor-presente">
								<span
									v-if="presente.nome.toUpperCase() != 'PIX'"
								>
									{{ formatarValorParaReal(presente.preco) }}
								</span>
								<span v-else> &nbsp; </span>
							</div>
							<div class="area-botao-presentear">
								<a-button
									class="botao-presente"
									@click="visualizarPresente(presente.id)"
									v-if="presente.disponivel"
								>
									PRESENTEAR
								</a-button>

								<a-button class="botao-presente" v-else>
									COMPRADO
								</a-button>
							</div>
						</div>
					</a-col>
				</a-row>

				<div
					id="nao-encontrado"
					v-if="listaPresentesComFiltro()?.length == 0 && !loading"
				>
					<a-empty
						description="Nenhum presente encontrado para esta categoria"
					/>
				</div>
			</div>
		</div>
	</a-spin>
</template>

<script>
import {
	AppstoreOutlined,
	MenuOutlined,
	RightOutlined,
} from "@ant-design/icons-vue";
import IconeCategoria from "@/components/IconeCategoria";
import API from "@/api";

export default {
	components: {
		IconeCategoria,
		AppstoreOutlined,
		MenuOutlined,
		RightOutlined,
	},
	data() {
		return {
			modoVisualizacao: "",
			ordenacao: "relevancia",
			categoriaSelecionada: "TODOS",
			data: [],
			listaPresentes: [],
			categorias: [],
			visible: false,
			windowHeight: 0,
			windowWidth: 0,
			loading: false,
		};
	},

	computed: {
		usuarioAutenticado() {
			return this.$store.getters.getUser;
		},
	},

	watch: {
		ordenacao(value) {
			if (value === "relevancia") {
				this.listaPresentesComFiltro().sort(
					(a, b) => a.relevancia - b.relevancia
				);
			} else if (value === "menor-valor") {
				this.listaPresentesComFiltro().sort(
					(a, b) => a.preco - b.preco
				);
			} else if (value === "maior-valor") {
				this.listaPresentesComFiltro().sort(
					(a, b) => b.preco - a.preco
				);
			} else if (value === "az") {
				this.listaPresentesComFiltro().sort((a, b) =>
					a.nome.localeCompare(b.nome)
				);
			} else if (value === "za") {
				this.listaPresentesComFiltro().sort((a, b) =>
					b.nome.localeCompare(a.nome)
				);
			}
		},

		windowWidth(val) {
			this.modoVisualizacao =
				this.windowHeight > this.windowWidth ? "mobile" : "desktop";
		},
	},

	created() {
		window.addEventListener("resize", this.resizeHandler);
	},

	destroyed() {
		window.removeEventListener("resize", this.resizeHandler);
	},

	mounted() {
		this.windowHeight = window.innerHeight;
		this.windowWidth = window.innerWidth;
		this.listarPresentes();
		this.consultarCategorias();
	},

	methods: {
		resizeHandler(e) {
			this.windowHeight = window.innerHeight;
			this.windowWidth = window.innerWidth;
		},

		irParaAdmin() {
			this.$router.push({ name: "presentesAdmin" });
		},

		visualizarPresente(idPresente) {
			this.$router.push({
				name: "finalizarCompra",
				params: { id: idPresente },
			});
		},

		sair() {
			this.$store.dispatch("logout");
			this.$router.push({ name: "entrar" });
		},

		formatarValorParaReal(val) {
			return `R$ ${val.toFixed(2)?.replace(".", ",")}`;
		},

		listaPresentesComFiltro() {
			if (this.categoriaSelecionada === "TODOS") {
				return this.listaPresentes;
			}

			return this.listaPresentes.filter(
				(presente) =>
					presente.categoriaPresente.nome.toUpperCase() ===
					this.categoriaSelecionada
			);
		},

		listarPresentes() {
			this.loading = true;

			API.consultarPresentes().then((response) => {
				this.listaPresentes = response.data;

				this.loading = false;
			});
		},

		consultarCategorias() {
			API.consultarCategoriasPresente().then((response) => {
				this.categorias = response.data;
			});
		},
	},
};
</script>

<style lang="less" scoped>
#area-filtros {
	width: 92%;
}

#area-filtros-desktop {
	width: 60%;
}

#area-produtos {
	width: 100%;
}

#area-produtos-desktop {
	max-width: 1129.8px;
}

#area-ordenacao {
	margin: 5px 0;
	z-index: 999;

	@supports not (scrollbar-width: none) {
		padding-top: 30px;
		background-color: #fff;
	}

	#ordenacao {
		padding: 20px 0;
	}
}

:deep(.ant-select-selector) {
	border-radius: 6px !important;
}

:deep(.ant-select-single.ant-select-show-arrow .ant-select-selection-item) {
	color: #d4b4d3;
	font-size: 16px !important;
}

#area-botao-menu-presentes {
	height: 100px;
	background-color: #c2a6c1;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-left: 7%;

	#botao-menu-presentes {
		background-color: #d4b4d3;
		font-size: 1.6em;
		color: #fff;
		font-weight: bolder;
		width: 50px;
		height: 50px;
		border: 4px solid #ce9f41;
		box-shadow: 0 0 0 4px #fff, 7px 7px 3px -2px rgba(0, 0, 0, 0.75);
	}

	#barra-menu-presentes {
		margin-top: -43px;
		margin-left: -200px;
		background-color: #c2a6c1;
		font-size: 5em;
		height: 40px;
		width: 230px;
	}
}

#titulo-pagina-presentes {
	margin-top: -40px;
	margin-left: 60px;

	#titulo-1-presentes {
		padding-top: 3px;
		font-size: 13px;
		line-height: 0px;
		color: #fff;
		user-select: none;
	}

	#titulo-2-presentes {
		font-size: 32px;
		line-height: 40px;
		font-weight: bold;
		color: #fff;
		user-select: none;
	}
}

#carregando {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

#conteudo-presente {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

#area-querido-convidado {
	text-align: center;
	margin-top: 10px;
}

#querido-convidado {
	width: 80%;
}

#area-texto {
	width: 90%;
	margin-top: 10px;
	p {
		text-align: justify;
		line-height: 12px;
	}
}

#area-texto-desktop {
	width: 90%;
	margin-top: 10px;
	p {
		text-align: center;
		line-height: 12px;
		font-size: 18px;
	}
}

#area-logo {
	text-align: center;
}

#logo-jd {
	width: 40%;
	margin: 10px 0;
}

.tag-categoria-selecionada {
	background-color: #d4b4d3;
	border-radius: 6px;
	padding: 6px;
	margin-right: 5px;
	font-size: 13px;
	color: #fff;
	cursor: pointer;
	display: inline-block;
}

.tag-categoria {
	background-color: #fff;
	border-radius: 6px;
	padding: 6px;
	margin: 5px;
	font-size: 13px;
	color: #d4b4d3;
	cursor: pointer;
	border: 1px solid #d4b4d3;
	display: inline-block;
}

.card-presente-vendido {
	width: 100%;
	padding-top: 13px;
	text-align: center;
	margin: 10px 0;

	.imagem-presente {
		width: 93%;
		height: auto;
		border-radius: 8px;
		border: 1px solid #e6e6e6;
		opacity: 0.4;
	}

	.nome-presente {
		opacity: 0.4;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 14px;
		width: 83%;
		font-weight: 700;
		color: #373434;
		text-align: left;
		margin-left: 10%;
		height: 30px;
		line-height: 14px;
		margin-top: 5px;
	}

	.valor-presente {
		opacity: 0.4;
		font-size: 14px;
		font-weight: 600;
		color: #373434;
		text-align: left;
		margin-left: 10%;
		margin-bottom: 7px;
	}

	.area-botao-presentear {
		display: flex;
		justify-content: center;
		align-items: center;

		.botao-presente {
			opacity: 0.4;
			border-radius: 8px;
			width: 85%;
			font-size: 12px;
			line-height: 12px;
			padding: 20px 0;
			background-color: #c2a6c1;
			color: #fff;
			border: none;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: not-allowed;
		}
	}
}
.card-presente {
	width: 100%;
	padding-top: 13px;
	text-align: center;
	margin: 10px 0;

	.imagem-presente {
		width: 93%;
		height: auto;
		border-radius: 8px;
		border: 1px solid #e6e6e6;
	}

	.nome-presente {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 14px;
		width: 83%;
		font-weight: 700;
		color: #373434;
		text-align: left;
		margin-left: 10%;
		height: 30px;
		line-height: 14px;
		margin-top: 5px;
	}

	.valor-presente {
		font-size: 14px;
		font-weight: 600;
		color: #373434;
		text-align: left;
		margin-left: 10%;
		margin-bottom: 7px;
	}

	.area-botao-presentear {
		display: flex;
		justify-content: center;
		align-items: center;

		.botao-presente {
			border-radius: 8px;
			width: 85%;
			font-size: 12px;
			line-height: 12px;
			padding: 20px 0;
			background-color: #c2a6c1;
			color: #fff;
			border: none;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

:deep(.ant-row-space-between) {
	width: 100% !important;
}

#nao-encontrado {
	margin-top: 50px;
}

#topo-presentes {
	height: 15%;
	background-color: #c2a6c1;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: -1;
}

#area-categorias {
	white-space: nowrap;
	overflow-x: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow: -webkit-scrollbars-none;
	z-index: 999;

	@supports not (scrollbar-width: none) {
		margin-bottom: -40px;
	}
}

.lista-menu {
	list-style-type: none;
	text-align: right;
}

.separador-menu {
	border: none;
	border-top: 2px dotted #fff;
	width: 100%;
}

.lista-item {
	width: 100%;
	font-weight: bolder;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	.item-menu {
		font-size: 1.3em;
		width: 70%;
		line-height: 1em;
		cursor: pointer;
		padding: 10px 20px;
	}

	.icone-menu {
		color: #6b604e;
		font-weight: bolder;
	}
}

.lista-item-presente {
	padding-top: 10px;
	font-size: 10px;
	font-weight: 400;
	letter-spacing: 1px;
}

#logo-fim-menu {
	position: fixed;
	bottom: 30px;
}

#icone-fechar-menu {
	margin-top: 10px;
	font-size: 2em;
	font-weight: bolder;
}

#area-sair {
	padding-top: 20px;
	cursor: pointer;
	user-select: none;
	font-size: 2em;
}

#titulo-pagina-desktop {
	margin-top: 10px;
	margin-left: 10px;

	#titulo-1 {
		font-size: 17px;
		line-height: 0px;
		color: #fff;
		letter-spacing: 1.5px;
	}

	#titulo-2 {
		font-size: 42px;
		line-height: 50px;
		font-weight: bold;
		color: #fff;
	}
}
</style>
