<template>
	<div class="titulo-paginas">
		Presentes
		<a-button size="small" type="primary" shape="circle">
			<router-link :to="{ name: 'cadastrarPresente' }">
				<PlusOutlined />
			</router-link>
		</a-button>

		<a-input
			v-model:value="pesquisar"
			placeholder="Pesquisar"
			style="margin-top: 10px"
		>
		</a-input>
	</div>

	<a-table
		:columns="columns"
		:data-source="dataVisivel"
		size="small"
		:loading="loading"
		rowKey="id"
	>
		<template #emptyText>
			<p>Nenhum presente cadastrado</p>
		</template>

		<template #bodyCell="{ column, record }">
			<template v-if="column.key === 'preco'">
				{{ formatarValorParaReal(record.preco) }}
			</template>

			<template v-if="column.key === 'disponivel'">
				<CheckOutlined style="color: green" v-if="record.disponivel" />
				<CloseOutlined style="color: red" v-if="!record.disponivel" />
			</template>

			<template v-if="column.key === 'ver'">
				<a-button type="link" size="small">
					<router-link
						:to="{
							name: 'visualizarPresente',
							params: { id: record.id },
						}"
					>
						<SearchOutlined />
					</router-link>
				</a-button>
			</template>
		</template>
	</a-table>
</template>

<script>
import {
	DeleteOutlined,
	EditOutlined,
	SearchOutlined,
	PlusOutlined,
	CheckOutlined,
	CloseOutlined,
} from "@ant-design/icons-vue";
import API from "@/api";

export default {
	components: {
		DeleteOutlined,
		EditOutlined,
		SearchOutlined,
		PlusOutlined,
		CheckOutlined,
		CloseOutlined,
	},
	data() {
		return {
			pesquisar: "",
			loading: false,
			data: [],
			dataVisivel: [],
			columns: [
				{
					title: "Nome",
					dataIndex: "nome",
					key: "nome",
				},
				{
					title: "Preço",
					dataIndex: "preco",
					key: "preco",
				},
				{
					title: "",
					dataIndex: "disponivel",
					key: "disponivel",
					align: "center",
				},
				{
					title: "",
					dataIndex: "relevancia",
					key: "relevancia",
					align: "center",
				},

				{
					title: "",
					key: "ver",
					width: "1px",
				},
			],
		};
	},

	watch: {
		pesquisar(value) {
			this.dataVisivel = this.data.filter((item) =>
				item.nome.toLowerCase().includes(value.toLowerCase())
			);
		},
	},

	mounted() {
		this.listarPresentes();
	},

	methods: {
		irParaAdmin() {
			this.$router.push({ name: "homeAdmin" });
		},

		listarPresentes() {
			this.loading = true;

			API.consultarPresentes()
				.then((response) => {
					this.data = response.data;
					this.dataVisivel = response.data;
				})
				.catch((error) => {
					console.error(error);
				})
				.finally(() => {
					this.loading = false;
				});
		},

		formatarValorParaReal(val) {
			return `R$ ${val.toFixed(2)?.replace(".", ",")}`;
		},
	},
};
</script>

<style lang="less" scoped></style>
