<template>
	<div class="titulo-paginas">
		Recados <a-badge :count="dataVisivel.length" />

		<a-input
			v-model:value="pesquisar"
			placeholder="Pesquisar"
			style="margin-top: 10px"
		>
		</a-input>

		<a-form-item label="Status" style="margin: 20px 0">
			<a-select
				v-model:value="filtro"
				style="width: 70%; margin-left: 10px"
				placeholder="Filtrar por status"
			>
				<a-select-option value="todos">Todos</a-select-option>
				<a-select-option value="aprovados">Aprovados</a-select-option>
				<a-select-option value="naoAprovados">
					Não Aprovados
				</a-select-option>
			</a-select>
		</a-form-item>
	</div>

	<div>
		<a-table
			:columns="columns"
			:data-source="dataVisivel"
			size="small"
			:loading="loading"
		>
			<template #bodyCell="{ column, record }">
				<template v-if="column.key === 'aprovado'">
					<a-popconfirm
						title="Deseja desaprovar o recado?"
						ok-text="Sim"
						cancel-text="Não"
						@confirm="aprovarRecado(record)"
						v-if="record.aprovado"
					>
						<a-button type="link" size="small" :disabled="loading">
							<CheckOutlined style="color: green" />
						</a-button>
					</a-popconfirm>

					<a-popconfirm
						title="Deseja aprovador o recado?"
						ok-text="Sim"
						cancel-text="Não"
						@confirm="aprovarRecado(record)"
						v-else
					>
						<a-button type="link" size="small" :disabled="loading">
							<CloseOutlined style="color: red" />
						</a-button>
					</a-popconfirm>
				</template>
			</template>
		</a-table>
	</div>
</template>

<script>
import API from "@/api";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons-vue";

export default {
	components: {
		CheckOutlined,
		CloseOutlined,
	},

	data() {
		return {
			filtro: "todos",
			loading: false,
			pesquisar: "",
			data: [],
			dataVisivel: [],
			columns: [
				{
					title: "Nome",
					dataIndex: "nome",
					key: "nome",
				},
				{
					title: "Mensagem",
					dataIndex: "mensagem",
					key: "mensagem",
				},
				{
					title: "Status",
					dataIndex: "aprovado",
					key: "aprovado",
					align: "center",
				},
			],
		};
	},

	watch: {
		pesquisar() {
			this.dataVisivel = this.data.filter((recado) => {
				return recado.nome
					.toLowerCase()
					.includes(this.pesquisar.toLowerCase());
			});
		},

		filtro(value) {
			if (this.filtro === "todos") {
				this.dataVisivel = this.data;
			} else {
				this.dataVisivel = this.data.filter((recado) => {
					return recado.aprovado === (this.filtro === "aprovados");
				});
			}
		},
	},

	mounted() {
		this.listarRecados();
	},

	methods: {
		listarRecados() {
			this.loading = true;

			API.listarRecados()
				.then((response) => {
					this.data = response.data;
					this.dataVisivel = response.data;

					if (this.$route.params.status == "naoAprovados") {
						this.filtro = this.$route.params.status;

						this.dataVisivel = this.data.filter((recado) => {
							return (
								recado.aprovado ===
								(this.filtro === "naoApovados")
							);
						});
					}
				})
				.catch((error) => {
					console.error(error);
				})
				.finally(() => {
					this.loading = false;
				});
		},

		aprovarRecado(recado) {
			this.loading = true;

			API.aprovarDesaprovarRecado(recado)
				.then(() => {
					this.listarRecados();
				})
				.catch((error) => {
					console.error(error);
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style lang="less" scoped></style>
