import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
	state: {
		token: null,
		autenticado: false,
		nome: null,
		senha: null,
		id: null,
		perfil: null,
		admNome: null,
		admLogin: null,
		tokenAdm: null,
		convidados: null,
	},
	getters: {
		getToken: (state) => state.token,
		getUser: (state) => {
			return {
				nome: state.nome,
				senha: state.senha,
				id: state.id,
				perfil: state.perfil,
				convidados: state.convidados,
			};
		},
		getAuth: (state) => state.autenticado,
		getAdmNome: (state) => state.admNome,
		getAdmLogin: (state) => state.admLogin,
		getTokenAdm: (state) => state.tokenAdm,
	},
	mutations: {
		setToken(state, token) {
			state.token = token;
		},
		setConvidados(state, convidados) {
			state.convidados = convidados;
		},
		setId(state, id) {
			state.id = id;
		},
		setNome(state, nome) {
			state.nome = nome;
		},
		setSenha(state, senha) {
			state.senha = senha;
		},
		setPerfil(state, perfil) {
			state.perfil = perfil;
		},
		setAuth(state, auth) {
			state.autenticado = auth;
		},
		setAdmNome(state, admNome) {
			state.admNome = admNome;
		},
		setAdmLogin(state, admLogin) {
			state.admLogin = admLogin;
		},
		setTokenAdm(state, tokenAdm) {
			state.tokenAdm = tokenAdm;
		},
	},
	actions: {
		login({ commit }, user) {
			commit("setAuth", true);
			commit("setToken", user.token);
			commit("setNome", user.nome);
			commit("setId", user.id);
			commit("setSenha", user.senha);
			commit("setPerfil", user.perfil);
			commit("setConvidados", user.convidados);
		},

		logout({ commit }) {
			commit("setAuth", false);
			commit("setToken", null);
			commit("setNome", null);
			commit("setId", null);
			commit("setSenha", null);
			commit("setPerfil", null);
		},

		atualizarConfirmacaoConvidado({ commit }, convidado) {
			commit("setConvidados", convidado);
		},

		loginAdm({ commit }, admin) {
			commit("setTokenAdm", admin.token);
			commit("setAdmNome", admin.nome);
			commit("setAdmLogin", admin.login);
		},

		logoutAdm({ commit }) {
			commit("setTokenAdm", null);
			commit("setAdmNome", null);
			commit("setAdmLogin", null);
		},
	},

	modules: {},

	plugins: [createPersistedState()],
});
