import axios from "axios";
import store from "@/store";
import administrador from "./modulos-convite/administrador";
import presente from "./modulos-convite/presente";
import categoriaPresente from "./modulos-convite/categoriaPresente";
import convidados from "./modulos-convite/convidados";
import convite from "./modulos-convite/convite";
import recado from "./modulos-convite/recado";

const instance = axios.create({
	baseURL: process.env.VUE_APP_CONVITE_API,
});

instance.defaults.responseType = "json";

instance.interceptors.request.use((config) => {
	if (store.state.tokenAdm)
		config.headers["Authorization"] = `Bearer ${store.state.tokenAdm}`;

	return config;
});

export default {
	...administrador({ instance }),
	...presente({ instance }),
	...categoriaPresente({ instance }),
	...convidados({ instance }),
	...convite({ instance }),
	...recado({ instance }),
};
