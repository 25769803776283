<template>
	<div v-if="modoVisualizacao == 'mobile'">
		<img src="@/assets/images/background-2.png" class="imagem-fundo" />

		<a-spin :spinning="loading">
			<template #indicator>
				<div
					style="
						display: flex;
						justify-content: center;
						align-items: center;
					"
				>
					<img
						src="@/assets/images/carregamento.gif"
						style="width: 150px; height: auto"
					/>
				</div>
			</template>

			<div id="conteudo-pagina">
				<div id="mensagem-pontualidade" class="font-montserrat">
					Iniciaremos a cerimônia <br />
					pontualmente
				</div>

				<div id="area-linha-tempo">
					<img
						src="@/assets/images/linha-tempo.png"
						id="linha-tempo"
					/>
				</div>

				<div id="area-cor-madrinha" class="font-montserrat">
					<div id="area-texto-cor">
						COR DAS <br />
						MADRINHAS
					</div>

					<div id="area-imagem-cor">
						<img
							src="@/assets/images/cor-madrinha.png"
							id="imagem-cor-madrinha"
						/>
					</div>
				</div>
			</div>

			<a-row type="flex" justify="space-between" align="center">
				<a-col :span="12">
					<div id="texto-faltam" class="font-montserrat">
						FALTAM...
					</div>
					<a-row type="flex" justify="center" align="center">
						<a-col :span="6">
							<div class="card-dias">
								<span class="valor font-montserrat">
									{{ dias }}
								</span>
							</div>
							<div class="legenda font-montserrat">Dias</div>
						</a-col>

						<a-col :span="6">
							<div class="card-dias">
								<span class="valor font-montserrat">
									{{ horas }}
								</span>
							</div>
							<div class="legenda font-montserrat">Horas</div>
						</a-col>

						<a-col :span="6">
							<div class="card-dias">
								<span class="valor font-montserrat">
									{{ minutos }}
								</span>
							</div>
							<div class="legenda font-montserrat">Minutos</div>
						</a-col>
					</a-row>
				</a-col>

				<a-col :span="12">
					<div>
						<img src="@/assets/images/data.png" width="150" />
					</div>
				</a-col>
			</a-row>

			<div id="conteudo-pagina">
				<div id="area-avisar" class="font-montserrat">
					CONFIRME A PRESENÇA
				</div>

				<div id="area-branco-so-noiva" class="font-montserrat">
					BRANCO SÓ A NOIVA!
				</div>

				<div id="area-evite-preto" class="font-montserrat">
					EVITE O PRETO <br />
					VISTASSE COM ALEGRIA
				</div>
			</div>
		</a-spin>
	</div>

	<div v-else>
		<div>
			<a-drawer
				placement="left"
				:visible="visible"
				@close="visible = false"
				class="desktop-menu"
				width="20%"
			>
				<template #closeIcon>
					<MenuOutlined id="icone-fechar-menu" class="texto-branco" />
				</template>

				<template #extra>
					<a-button
						style="margin-right: 8px"
						@click="irParaAdmin()"
						v-if="usuarioAutenticado.perfil == 'ADMIN'"
					>
						Admin
					</a-button>
				</template>

				<nav id="menu-navegacao">
					<router-link
						:to="{ name: 'home' }"
						class="lista-item"
						@click="visible = false"
					>
						<div class="texto-branco lista-item font-custom">
							<span class="item-menu"> TELA INICIAL </span>
							<RightOutlined class="icone-menu" />
						</div>
					</router-link>

					<hr class="separador-menu-desktop" />

					<router-link
						:to="{ name: 'convite' }"
						class="lista-item"
						@click="visible = false"
					>
						<div class="texto-branco lista-item font-custom">
							<span class="item-menu"> O CONVITE </span>
							<RightOutlined class="icone-menu" />
						</div>
					</router-link>

					<hr class="separador-menu-desktop" />

					<router-link
						:to="{ name: 'detalhes' }"
						class="lista-item"
						@click="visible = false"
					>
						<div class="texto-branco lista-item font-custom">
							<span class="item-menu"> O CASAMENTO </span>
							<RightOutlined class="icone-menu" />
						</div>
					</router-link>

					<hr class="separador-menu-desktop" />

					<router-link
						:to="{ name: 'confirmacao' }"
						class="lista-item"
						@click="visible = false"
					>
						<div class="texto-branco lista-item font-custom">
							<span class="item-menu"> CONFIRMAR PRESENÇA </span>
							<RightOutlined class="icone-menu" />
						</div>
					</router-link>

					<hr class="separador-menu-desktop" />

					<router-link
						:to="{ name: 'recados' }"
						class="lista-item"
						@click="visible = false"
					>
						<div class="texto-branco lista-item font-custom">
							<span class="item-menu"> DEIXE SEU RECADO </span>
							<RightOutlined class="icone-menu" />
						</div>
					</router-link>

					<hr class="separador-menu-desktop" />

					<router-link
						:to="{ name: 'presentes' }"
						class="lista-item"
						@click="visible = false"
					>
						<div class="texto-branco lista-item-presente">
							DESEJA NOS PRESENTEAR ?
						</div>
					</router-link>

					<div @click="sair()" id="area-sair">
						<span class="texto-branco font-custom"> SAIR </span>
					</div>
				</nav>

				<div style="text-align: center">
					<img
						src="@/assets/images/logo-dj.png"
						:style="
							modoVisualizacao == 'mobile'
								? 'width: 80%'
								: 'width: 50%'
						"
					/>
				</div>
			</a-drawer>
			<div id="area-botao-menu-desktop">
				<a-button
					shape="circle"
					:id="
						modoVisualizacao == 'mobile'
							? 'botao-menu'
							: 'botao-menu-desktop'
					"
					@click="visible = true"
				>
					<MenuOutlined />
				</a-button>

				<div id="barra-menu-desktop"></div>

				<div id="titulo-pagina-desktop">
					<div id="titulo-1" class="font-montserrat">
						{{ tituloPagina()[0] }}
					</div>
					<div id="titulo-2">{{ tituloPagina()[1] }}</div>
				</div>
			</div>
		</div>

		<img src="@/assets/images/fundo-desktop.png" class="imagem-fundo" />
		<a-row id="area-detalhes-desktop">
			<a-col :span="12">
				<a-row type="flex" justify="center" align="center">
					<a-col :span="24">
						<div>
							<span class="titulo-desktop font-montserrat">
								Iniciaremos a cerimônia pontualmente
							</span>
						</div>

						<div style="margin-top: 30px">
							<a-row type="flex" justify="center" align="center">
								<a-col :span="10">
									<span
										class="titulo-cor-madrinhas font-montserrat"
									>
										COR DAS <br />
										MADRINHAS
									</span>
								</a-col>

								<a-col :span="10">
									<img
										src="@/assets/images/cor-madrinha.png"
										style="width: 80%"
									/>
								</a-col>
							</a-row>
						</div>
					</a-col>

					<a-col :span="12"> </a-col>
				</a-row>

				<a-row
					type="flex"
					justify="start"
					align="start"
					style="margin-top: 30px"
				>
					<a-col :span="12">
						<div id="texto-faltam-desktop" class="font-montserrat">
							FALTAM...
						</div>

						<a-row
							type="flex"
							justify="center"
							align="center"
							style="margin-top: 10px"
						>
							<a-col :span="6">
								<div class="card-dias-desktop">
									<span class="valor font-montserrat">
										{{ dias }}
									</span>
								</div>
								<div class="legenda-desktop font-montserrat">
									Dias
								</div>
							</a-col>

							<a-col :span="6">
								<div class="card-dias-desktop">
									<span class="valor font-montserrat">
										{{ horas }}
									</span>
								</div>
								<div class="legenda-desktop font-montserrat">
									Horas
								</div>
							</a-col>

							<a-col :span="6">
								<div class="card-dias-desktop">
									<span class="valor font-montserrat">
										{{ minutos }}
									</span>
								</div>
								<div class="legenda-desktop font-montserrat">
									Minutos
								</div>
							</a-col>
						</a-row>
					</a-col>

					<a-col :span="12">
						<div>
							<img src="@/assets/images/data.png" width="200" />
						</div>
					</a-col>
				</a-row>

				<a-row
					type="flex"
					justify="start"
					align="start"
					style="margin-top: 30px"
				>
					<a-col :span="24">
						<span class="subtitulo-desktop font-montserrat">
							Branco só a noiva!
						</span>
					</a-col>
				</a-row>

				<a-row
					type="flex"
					justify="start"
					align="start"
					style="margin-top: 20px"
				>
					<a-col :span="24">
						<span class="subtitulo-desktop font-montserrat">
							Evite o preto <br />
							vistasse com alegria
						</span>
					</a-col>
				</a-row>
			</a-col>

			<a-col :span="12">
				<img
					src="@/assets/images/linha-tempo.png"
					style="width: 100%"
				/>

				<img
					src="@/assets/images/jd-detalhes.png"
					style="width: 40%; margin-top: -20px"
				/>
			</a-col>
		</a-row>
	</div>
</template>

<script>
import { MenuOutlined, RightOutlined } from "@ant-design/icons-vue";
import Menu from "@/components/MenuComponent.vue";

export default {
	components: {
		MenuOutlined,
		RightOutlined,
		Menu,
	},
	data() {
		return {
			visible: false,
			windowHeight: 0,
			windowWidth: 0,
			dias: 0,
			horas: 0,
			minutos: 0,
			loading: true,
			modoVisualizacao: "",
		};
	},

	computed: {
		nameRouter() {
			return this.$route.name;
		},

		usuarioAutenticado() {
			return this.$store.getters.getUser;
		},
	},

	created() {
		window.addEventListener("resize", this.resizeHandler);
	},

	destroyed() {
		window.removeEventListener("resize", this.resizeHandler);
	},

	mounted() {
		this.windowHeight = window.innerHeight;
		this.windowWidth = window.innerWidth;
		this.contadorRegressivo();

		setTimeout(() => {
			this.loading = false;
		}, 1000);
	},

	watch: {
		windowWidth(val) {
			this.modoVisualizacao =
				this.windowHeight > this.windowWidth ? "mobile" : "desktop";
		},
	},

	methods: {
		irParaAdmin() {
			this.$router.push({ name: "homeAdmin" });
		},

		sair() {
			this.$store.dispatch("logout");
			this.$router.push({ name: "entrar" });
		},

		resizeHandler(e) {
			this.windowHeight = window.innerHeight;
			this.windowWidth = window.innerWidth;
		},

		contadorRegressivo() {
			const dataAlvo = new Date("2024-11-09T00:00:00");

			setInterval(() => {
				const dataAtual = new Date();
				const diferenca = dataAlvo - dataAtual;

				this.dias = Math.floor(diferenca / (1000 * 60 * 60 * 24));
				this.horas = Math.floor(
					(diferenca % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
				);
				this.minutos = Math.floor(
					(diferenca % (1000 * 60 * 60)) / (1000 * 60)
				);
				this.segundos = Math.floor((diferenca % (1000 * 60)) / 1000);
			}, 1000);
		},

		tituloPagina() {
			switch (this.$route.name) {
				case "home":
					return ["", ""];
				case "detalhes":
					return ["CASAMENTO", "DETALHES"];
				case "recados":
					return ["DEIXE SEU", "RECADO"];
				case "confirmacao":
					return ["CONFIRME", "PRESENÇA"];
				case "presentes":
					return ["LISTA DE", "PRESENTES"];
				case "confirmarPresenca":
					return ["CONFIRMAR", "PRESENÇA"];
				default:
					return ["", ""];
			}
		},
	},
};
</script>

<style lang="less" scoped>
#area-detalhes-desktop {
	text-align: center;
	max-width: 1129.8px;
	margin: 0 auto;
	height: 80vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.titulo-desktop {
	font-size: 22px;
	color: #373434;
	text-transform: uppercase;
	letter-spacing: 2px;
	line-height: 29px;
}

.subtitulo-desktop {
	font-size: 20px;
	color: #373434;
	text-transform: uppercase;
	letter-spacing: 2px;
	line-height: 29px;
}

.titulo-cor-madrinhas {
	font-size: 18px;
	color: #373434;
	text-transform: uppercase;
	letter-spacing: 2px;
	line-height: 29px;
}

#conteudo-pagina {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
}

#mensagem-pontualidade {
	font-size: 15px;
	line-height: 15px;
	text-align: center;
	margin-top: 20px;
	text-transform: uppercase;
	color: #373434;
	font-weight: 300;
	letter-spacing: 1px;
}

#linha-tempo {
	width: 90%;
}

#area-linha-tempo {
	text-align: center;
	margin-top: 20px;
}

#area-cor-madrinha {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;

	#area-texto-cor {
		font-size: 18px;
		line-height: 18px;
		text-align: center;
		width: 50%;
		font-weight: 300;
	}

	#area-imagem-cor {
		width: 50%;

		#imagem-cor-madrinha {
			width: 70%;
		}
	}
}

#texto-faltam {
	display: inline;
	background-color: #e5daf0;
	font-size: 10px;
	padding: 1px 5px;
	margin-left: 10%;
}

#texto-faltam-desktop {
	display: inline;
	background-color: #e5daf0;
	font-size: 16px;
	padding: 1px 5px;
	margin-left: -50%;
}

.legenda-desktop {
	font-size: 13px;
	text-align: center;
	letter-spacing: 0;
	color: #979393;
}

.card-dias-desktop {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #faeaf3;
	color: #979393;
	border: none;
	cursor: default;
	width: 80%;
	height: 40px;
	font-size: 16px;
	font-weight: 500;
	margin: 0 5px;

	.valor {
		z-index: 99;
	}
}

.legenda {
	font-size: 11px;
	text-align: center;
	letter-spacing: 0;
	color: #979393;
}

.card-dias {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #faeaf3;
	color: #979393;
	border: none;
	cursor: default;
	width: 80%;
	height: 40px;
	font-size: 14px;
	font-weight: 500;
	margin: 0 5px;

	.valor {
		z-index: 99;
	}
}

#area-data {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 15px;
	width: 100%;
}

/*
#ara-info {
	// display: flex;
	// justify-content: center;
	// align-items: flex-start;
	// flex-direction: column;
	// width: 50%;
}
*/

/*
#area-contagem-regressiva {
	// width: 50%;
}
*/

#area-avisar {
	text-transform: uppercase;
	font-size: 15px;
	line-height: 15px;
	text-align: center;
	margin-top: 20px;
}

#area-branco-so-noiva {
	font-size: 15px;
	line-height: 15px;
	text-align: center;
	margin-top: 20px;
	text-transform: uppercase;
	color: #373434;
	font-weight: 300;
	letter-spacing: 1px;
}

#area-evite-preto {
	font-size: 11px;
	line-height: 11px;
	text-align: center;
	margin-top: 20px;
	text-transform: uppercase;
	color: #373434;
	font-weight: 300;
	letter-spacing: 1px;
}

.lista-item {
	width: 100%;
	font-weight: bolder;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	.item-menu {
		font-size: 1.3em;
		width: 70%;
		line-height: 1em;
		cursor: pointer;
		padding: 10px 20px;
	}

	.icone-menu {
		color: #6b604e;
		font-weight: bolder;
	}
}

#area-sair {
	padding-top: 20px;
	cursor: pointer;
	user-select: none;
	font-size: 2em;
}

.separador-menu-desktop {
	border: none;
}

.lista-item-presente {
	padding-top: 10px;
	font-size: 10px;
	font-weight: 400;
	letter-spacing: 1px;
}

#titulo-pagina-desktop {
	margin-left: 10px;

	#titulo-1 {
		font-size: 17px;
		line-height: 0px;
		color: #fff;
		letter-spacing: 1.5px;
	}

	#titulo-2 {
		font-size: 42px;
		line-height: 50px;
		font-weight: bold;
		color: #fff;
	}
}
</style>
