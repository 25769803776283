<template>
	<div v-if="modoVisualizacao == 'mobile'">
		<img src="@/assets/images/background.png" class="imagem-fundo" />

		<a-spin :spinning="loading">
			<template #indicator>
				<div
					style="
						display: flex;
						justify-content: center;
						align-items: center;
					"
				>
					<img
						src="@/assets/images/carregamento.gif"
						style="width: 150px; height: auto"
					/>
				</div>
			</template>
			<div id="conteudo-pagina">
				<div class="texto-maiusculo">CLIQUE PARA ABRIR</div>

				<div>
					<PlayCircleOutlined
						id="play"
						@click="reproduzir()"
						title="Convite Especial"
					/>
				</div>

				<img src="@/assets/images/save-the-date.png" width="230" />

				<div id="area-sexta">
					<span class="letra-1"> ● </span>
					<span class="letra-2"> S </span>
					<span class="letra-3"> E </span>
					<span class="letra-4"> X </span>
					<span class="letra-5"> T </span>
					<span class="letra-6"> A </span>
					<span class="letra-7"> ● </span>
				</div>

				<div id="area-data">
					<span class="mes"> Nov </span>
					<span class="dia"> 08 </span>
					<span class="hora"> 14:30h </span>
				</div>

				<div id="area-guarde-nossa-data">
					<span> GUARDE NOSSA DATA </span>
				</div>

				<div id="area-botoes">
					<span>
						<a
							href="https://goo.gl/maps/9KYdZPXdFmyWs5t9A"
							target="_blank"
						>
							<img
								src="@/assets/images/local.png"
								class="icones"
							/>
							<span class="font-custom legenda-icone text-dark">
								Local <br />
								do evento
							</span>
						</a>
					</span>

					<span>
						<router-link :to="{ name: 'confirmacao' }">
							<img
								src="@/assets/images/confirmar.png"
								class="icones"
							/>
							<span class="legenda-icone font-custom text-dark">
								confirmar <br />
								presença
							</span>
						</router-link>
					</span>

					<a-tooltip title="Em breve">
						<span>
							<a disabled>
								<img
									src="@/assets/images/filtro.png"
									class="icones desativado"
								/>
								<span
									class="legenda-icone font-custom text-dark desativado"
								>
									use nosso <br />
									filtro
								</span>
							</a>
						</span>
					</a-tooltip>
				</div>

				<div id="area-info">
					<img src="@/assets/images/icons/mao.png" width="18" />
					<span> CLIQUE PARA INTERAGIR </span>
				</div>
			</div>
		</a-spin>
	</div>

	<div v-else>
		<div>
			<a-drawer
				placement="left"
				:visible="visible"
				@close="visible = false"
				class="desktop-menu"
				width="20%"
			>
				<template #closeIcon>
					<MenuOutlined id="icone-fechar-menu" class="texto-branco" />
				</template>

				<template #extra>
					<a-button
						style="margin-right: 8px"
						@click="irParaAdmin()"
						v-if="usuarioAutenticado.perfil == 'ADMIN'"
					>
						Admin
					</a-button>
				</template>

				<nav id="menu-navegacao">
					<router-link
						:to="{ name: 'home' }"
						class="lista-item"
						@click="visible = false"
					>
						<div class="texto-branco lista-item font-custom">
							<span class="item-menu"> TELA INICIAL </span>
							<RightOutlined class="icone-menu" />
						</div>
					</router-link>

					<hr class="separador-menu-desktop" />

					<router-link
						:to="{ name: 'convite' }"
						class="lista-item"
						@click="visible = false"
					>
						<div class="texto-branco lista-item font-custom">
							<span class="item-menu"> O CONVITE </span>
							<RightOutlined class="icone-menu" />
						</div>
					</router-link>

					<hr class="separador-menu-desktop" />

					<router-link
						:to="{ name: 'detalhes' }"
						class="lista-item"
						@click="visible = false"
					>
						<div class="texto-branco lista-item font-custom">
							<span class="item-menu"> O CASAMENTO </span>
							<RightOutlined class="icone-menu" />
						</div>
					</router-link>

					<hr class="separador-menu-desktop" />

					<router-link
						:to="{ name: 'confirmacao' }"
						class="lista-item"
						@click="visible = false"
					>
						<div class="texto-branco lista-item font-custom">
							<span class="item-menu"> CONFIRMAR PRESENÇA </span>
							<RightOutlined class="icone-menu" />
						</div>
					</router-link>

					<hr class="separador-menu-desktop" />

					<router-link
						:to="{ name: 'recados' }"
						class="lista-item"
						@click="visible = false"
					>
						<div class="texto-branco lista-item font-custom">
							<span class="item-menu"> DEIXE SEU RECADO </span>
							<RightOutlined class="icone-menu" />
						</div>
					</router-link>

					<hr class="separador-menu-desktop" />

					<router-link
						:to="{ name: 'presentes' }"
						class="lista-item"
						@click="visible = false"
					>
						<div class="texto-branco lista-item-presente">
							DESEJA NOS PRESENTEAR ?
						</div>
					</router-link>

					<div @click="sair()" id="area-sair">
						<span class="texto-branco font-custom"> SAIR </span>
					</div>
				</nav>

				<div style="text-align: center">
					<img
						src="@/assets/images/logo-dj.png"
						:style="
							modoVisualizacao == 'mobile'
								? 'width: 80%'
								: 'width: 50%'
						"
					/>
				</div>
			</a-drawer>
			<a-affix id="area-botao-menu-desktop-layout">
				<a-button
					shape="circle"
					:id="
						modoVisualizacao == 'mobile'
							? 'botao-menu'
							: 'botao-menu-desktop'
					"
					@click="visible = true"
				>
					<MenuOutlined />
				</a-button>

				<div id="barra-menu-desktop"></div>
			</a-affix>
		</div>

		<img
			src="@/assets/images/home-desktop.png"
			class="imagem-fundo-desktop"
		/>

		<div id="area-desktop">
			<PlayCircleOutlined
				id="play-desktop"
				@click="reproduzir()"
				title="Convite Especial"
			/>

			<img src="@/assets/images/nomes-home.png" style="width: 45%" />

			<!-- <div id="area-botao-confirmar" class="font-montserrat">
				<button class="botao" @click="modalConfirmacao = true">
					CONFIRME PRESENÇA
				</button>
			</div> -->

			<div id="area-botoes-desktop">
				<span>
					<a
						href="https://goo.gl/maps/9KYdZPXdFmyWs5t9A"
						target="_blank"
					>
						<img
							src="@/assets/images/local.png"
							class="icones-desktop"
						/>
						<span
							class="font-custom legenda-icone-desktop text-dark"
						>
							Local <br />
							do evento
						</span>
					</a>
				</span>

				<span>
					<router-link :to="{ name: 'confirmacao' }">
						<img
							src="@/assets/images/confirmar.png"
							class="icones-desktop"
						/>
						<span
							class="legenda-icone-desktop font-custom text-dark"
						>
							confirmar <br />
							presença
						</span>
					</router-link>
				</span>

				<a-tooltip title="Em breve">
					<span>
						<a disabled>
							<img
								src="@/assets/images/filtro.png"
								class="icones-desktop desativado"
							/>

							<span
								class="legenda-icone-desktop font-custom text-dark desativado"
							>
								use nosso <br />
								filtro
							</span>
						</a>
					</span>
				</a-tooltip>
			</div>

			<div id="area-frases">
				<span class="text-lilas">
					Estamos felizes que você faça parte desse dia tao especial!
				</span>
				<span class="text-lilas">
					SERÁ UM PRAZER CELEBRAR COM VOCÊ!
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import {
	PlayCircleOutlined,
	MenuOutlined,
	RightOutlined,
} from "@ant-design/icons-vue";
import Menu from "@/components/MenuComponent.vue";

export default {
	components: {
		PlayCircleOutlined,
		MenuOutlined,
		RightOutlined,
		Menu,
	},
	data() {
		return {
			visible: false,
			loading: true,
			windowHeight: 0,
			windowWidth: 0,
			modoVisualizacao: "",
		};
	},

	computed: {
		usuarioAutenticado() {
			return this.$store.getters.getUser;
		},
	},

	watch: {
		windowWidth(val) {
			this.modoVisualizacao =
				this.windowHeight > this.windowWidth ? "mobile" : "desktop";
		},
	},

	created() {
		window.addEventListener("resize", this.resizeHandler);
	},

	destroyed() {
		window.removeEventListener("resize", this.resizeHandler);
	},

	mounted() {
		this.windowHeight = window.innerHeight;
		this.windowWidth = window.innerWidth;

		this.modoVisualizacao =
			this.windowHeight > this.windowWidth ? "mobile" : "desktop";

		setTimeout(() => {
			this.loading = false;
		}, 1700);
	},

	methods: {
		irParaAdmin() {
			this.$router.push({ name: "homeAdmin" });
		},

		reproduzir() {
			window.open(
				"https://invit.com.br/especiales/JessicaeDolglas.mp4",
				"_blank"
			);
		},

		sair() {
			this.$store.dispatch("logout");
			this.$router.push({ name: "entrar" });
		},

		resizeHandler(e) {
			this.windowHeight = window.innerHeight;
			this.windowWidth = window.innerWidth;
		},
	},
};
</script>

<style lang="less" scoped>
#conteudo-pagina {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 18%;
}

.texto-maiusculo {
	font-size: 12px;
	padding-bottom: 10px;
	line-height: 12px;
	color: gray;
}

#play {
	color: #fff;
	font-size: 7em;
	position: absolute;
	opacity: 0.7;
	margin: 45px -50px;
}

#play:hover {
	opacity: 1;
}

#play-desktop {
	color: #fff;
	font-size: 8em;
	position: absolute;
	opacity: 0.5;
	position: absolute;
	top: 30%;
	text-align: center;
}

@media (min-width: 1600px) {
	#play-desktop {
		top: 28%;
		font-size: 9em;
	}
}

#play-desktop:hover {
	opacity: 1;
}

#area-sexta {
	display: block;
	text-align: center;
	margin-top: -20px;
	color: #5f5e5d;

	span {
		display: inline-block;
		font-size: 1.2em;
		font-weight: bolder;
	}
}

#area-data {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100%;
	margin-top: -10px;

	.mes {
		font-size: 28px;
		font-weight: bolder;
		color: #5f5e5d;
	}

	.dia {
		font-size: 55px;
		font-weight: bolder;
		color: #c2a6c1;
		padding: 0 10px;
	}

	.hora {
		font-size: 18px;
		font-weight: bolder;
		color: #5f5e5d;
	}
}

#area-guarde-nossa-data {
	line-height: 0;
	margin-top: -5px;
	span {
		letter-spacing: 2px;
		font-size: 11px;
		color: gray;
	}
}

#area-botoes {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 5px;

	.legenda-icone {
		display: block;
		margin-top: -10px;
		font-size: 1em;
		line-height: 0.7em;
		text-align: center;
	}

	.icones {
		width: 60px;
		margin: 10px 10px;
		cursor: pointer;
	}
}

#area-info {
	margin-top: 10px;
	span {
		font-size: 10px;
		color: gray;
	}
}

.letra-1 {
	-webkit-transform: translateX(-1px) translateY(20px) rotate(0deg);
	transform: translateX(-1px) translateY(20px) rotate(0deg);
}

.letra-2 {
	-webkit-transform: translateX(-1px) translateY(15px) rotate(-24deg);
	transform: translateX(-1px) translateY(15px) rotate(-24deg);
}

.letra-3 {
	-webkit-transform: translateX(-1px) translateY(10px) rotate(-15deg);
	transform: translateX(-1px) translateY(10px) rotate(-15deg);
}

.letra-4 {
	-webkit-transform: translateX(-1px) translateY(9px) rotate(0deg);
	transform: translateX(-1px) translateY(9px) rotate(0deg);
}

.letra-5 {
	-webkit-transform: translateX(-1px) translateY(10px) rotate(15deg);
	transform: translateX(-1px) translateY(10px) rotate(15deg);
}

.letra-6 {
	-webkit-transform: translateX(-1px) translateY(15px) rotate(24deg);
	transform: translateX(-1px) translateY(15px) rotate(24deg);
}

.letra-7 {
	-webkit-transform: translateX(-1px) translateY(20px) rotate(0);
	transform: translateX(-1px) translateY(20px) rotate(0);
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////// DESKTOP
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

#area-desktop {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 3%;
}

.botao {
	margin-top: 10px;
	border-radius: 24px;
	padding: 15px 20px;
	font-size: 12px;
	line-height: 22px;
	background-color: #fdd6e7;
	color: #644f44;
	border: none;
	cursor: pointer;
}

#area-botoes-desktop {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 5px;

	.legenda-icone-desktop {
		display: block;
		margin-top: -10px;
		font-size: 1.1em;
		line-height: 0.7em;
		text-align: center;
	}

	.icones-desktop {
		width: 60px;
		margin: 10px 10px;
		cursor: pointer;
	}
}

#area-frases {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 10px;
	font-size: 1.2em;
	color: #5f5e5d;
}

.lista-item {
	width: 100%;
	font-weight: bolder;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	.item-menu {
		font-size: 1.3em;
		width: 70%;
		line-height: 1em;
		cursor: pointer;
		padding: 10px 20px;
	}

	.icone-menu {
		color: #6b604e;
		font-weight: bolder;
	}
}

#area-sair {
	padding-top: 20px;
	cursor: pointer;
	user-select: none;
	font-size: 2em;
}

.separador-menu-desktop {
	border: none;
}

.lista-item-presente {
	padding-top: 10px;
	font-size: 10px;
	font-weight: 400;
	letter-spacing: 1px;
}

.desativado {
	pointer-events: none;
}
</style>
