<template>
	<a-page-header
		:onBack="() => $router.push({ name: 'presentesAdmin' })"
		title="Adicionar Presente"
	/>

	<div id="area">
		<a-form :rules="rules" :model="formState" @finish="salvarPresente">
			<a-form-item label="Nome" class="form-item" name="nome">
				<a-input v-model:value="formState.nome" />
			</a-form-item>

			<a-row type="flex" justify="space-between">
				<a-col :span="14">
					<a-form-item label="Preço" class="form-item" name="preco">
						<a-input v-model:value="formState.preco" />
					</a-form-item>
				</a-col>

				<a-col :span="8">
					<a-form-item
						label="Relevância"
						class="form-item"
						name="relevancia"
					>
						<a-input-number
							v-model:value="formState.relevancia"
							style="width: 100%"
							min="0"
						/>
					</a-form-item>
				</a-col>
			</a-row>

			<a-form-item label="Categoria" class="form-item" name="categoriaId">
				<a-select
					v-model:value="formState.categoriaId"
					placeholder="Selecione uma categoria"
				>
					<template
						v-for="categoria in categorias"
						:key="categoria.id"
					>
						<a-select-option :value="categoria.id">
							{{ categoria.nome }}
						</a-select-option>
					</template>
				</a-select>
			</a-form-item>

			<a-form-item label="Descrição" class="form-item" name="descricao">
				<a-input v-model:value="formState.descricao" />
			</a-form-item>

			<a-form-item label="Link" class="form-item" name="link">
				<a-input v-model:value="formState.link" />
			</a-form-item>

			<a-row type="flex" justify="space-between">
				<a-col>
					<a-form-item label="" name="imagem">
						<a-upload
							v-model:file-list="fileList"
							list-type="picture-card"
							@preview="handlePreview"
							class="upload-no-border"
							:capture="null"
							accept="image/png,image/jpeg,image/jpg"
						>
							<div v-if="fileList.length < 1">
								<plus-outlined />
								<div style="margin-top: 8px">
									Carregar imagem
								</div>
							</div>
						</a-upload>
					</a-form-item>
				</a-col>

				<a-col>
					<a-form-item label="Disponível">
						<a-switch v-model:checked="formState.disponivel" />
					</a-form-item>
				</a-col>
			</a-row>

			<a-row type="flex" justify="end">
				<a-form-item style="margin-right: 10px">
					<router-link :to="{ name: 'presentesAdmin' }">
						<a-button> Voltar </a-button>
					</router-link>
				</a-form-item>

				<a-form-item>
					<a-button type="primary" html-type="submit">
						Salvar
					</a-button>
				</a-form-item>
			</a-row>
		</a-form>
	</div>

	<a-modal
		:visible="previewVisible"
		:title="previewTitle"
		:footer="null"
		@cancel="previewVisible = false"
	>
		<img alt="example" style="width: 100%" :src="previewImage" />
	</a-modal>
</template>

<script>
import API from "@/api";
import { PlusOutlined } from "@ant-design/icons-vue";

export default {
	components: {
		PlusOutlined,
	},

	data() {
		return {
			fileList: [],
			previewVisible: false,
			previewImage: "",
			previewTitle: "",
			loading: false,
			categorias: [],
			formState: {
				nome: "",
				preco: "",
				descricao: "",
				categoriaId: null,
				link: "",
				imagem: "",
				disponivel: true,
				relevancia: 0,
			},
			rules: {
				nome: [
					{
						required: true,
						message: "",
					},
				],
				preco: [
					{
						required: true,
						message: "",
					},
				],
				descricao: [
					{
						required: true,
						message: "",
					},
				],
				categoriaId: [
					{
						required: true,
						message: "",
					},
				],
				link: [
					{
						required: true,
						message: "",
					},
				],
				imagem: [
					{
						required: true,
						message: "Insira imagem",
					},
				],
				relevancia: [
					{
						required: true,
						message: "",
					},
				],
			},
		};
	},

	watch: {
		fileList: {
			handler(newVal, oldVal) {
				if (newVal.length > 0) {
					const firstFile = newVal[0];
					if (firstFile.thumbUrl) {
						this.formState.imagem = firstFile.thumbUrl;
					}
				}
			},
			deep: true,
		},
	},

	mounted() {
		this.listarCategoriasPresente();
	},

	methods: {
		async handlePreview(file) {
			if (!file.url && !file.preview) {
				try {
					const preview = await this.getBase64(file.originFileObj);
					file.preview = preview;
					this.previewImage = preview; // Update previewImage with base64 directly
					this.previewVisible = true;
					this.previewTitle =
						file.name ||
						file.url.substring(file.url.lastIndexOf("/") + 1);
				} catch (error) {
					console.error("Error generating preview:", error);
				}
			} else {
				this.previewImage = file.url || file.preview;
				this.previewVisible = true;
				this.previewTitle =
					file.name ||
					file.url.substring(file.url.lastIndexOf("/") + 1);
			}
		},

		getBase64(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => resolve(reader.result);
				reader.onerror = (error) => reject(error);
			});
		},

		salvarPresente() {
			this.loading = true;

			API.adicionarPresente(this.formState)
				.then(() => {
					this.$message.success("Presente adicionado com sucesso");
					this.$router.push({ name: "presentesAdmin" });

					this.loading = false;
				})
				.catch(() => {
					this.$message.error("Erro ao adicionar presente");
					this.loading = false;
				});
		},

		listarCategoriasPresente() {
			API.consultarCategoriasPresente().then((response) => {
				this.categorias = response.data;
			});
		},
	},
};
</script>

<style lang="less" scoped>
.form-item {
	margin-top: -20px;
}

#area {
	padding: 10px;
}

:deep(.ant-upload-list-picture-card .ant-upload-list-item-error) {
	border: none !important;
}
</style>
