<template>
	<a-menu
		v-model:selectedKeys="current"
		mode="horizontal"
		style="margin-bottom: 10px"
	>
		<a-menu-item key="homeAdmin">
			<router-link :to="{ name: 'homeAdmin' }"> Home </router-link>
		</a-menu-item>

		<a-menu-item key="conviteAdmin">
			<router-link :to="{ name: 'conviteAdmin' }">Convite </router-link>
		</a-menu-item>

		<a-menu-item key="convidadosAdmin">
			<router-link :to="{ name: 'convidadosAdmin' }">
				Convidados
			</router-link>
		</a-menu-item>

		<a-menu-item key="presentesAdmin">
			<router-link :to="{ name: 'presentesAdmin' }">
				Presentes
			</router-link>
		</a-menu-item>

		<a-menu-item key="recadosAdmin">
			<router-link :to="{ name: 'recadosAdmin' }"> Recados </router-link>
		</a-menu-item>

		<a-menu-item key="site">
			<router-link :to="{ name: 'home' }"> Site </router-link>
		</a-menu-item>

		<a-menu-item @click="sair()"> Sair </a-menu-item>
	</a-menu>

	<router-view />
</template>

<script>
export default {
	data() {
		return {
			current: ["homeAdmin"],
		};
	},

	computed: {
		nomeRotas() {
			return this.$route.name;
		},
	},

	watch: {
		nomeRotas() {
			this.current = [this.nomeRotas];
		},
	},

	methods: {
		sair() {
			this.$store.dispatch("logoutAdm");
			this.$router.push({ name: "loginAdministrativo" });
		},
	},
};
</script>

<style></style>
