export default ({ instance }) => {
	return {
		listarRecados() {
			return instance({
				method: "get",
				url: "recado",
			});
		},

		listarRecadosAprovados() {
			return instance({
				method: "get",
				url: "recado/aprovados",
			});
		},

		quantidadeRecadosAprovados() {
			return instance({
				method: "get",
				url: "recado/quantidade-aprovados",
			});
		},

		quantidadeRecadosTotal() {
			return instance({
				method: "get",
				url: "recado/quantidade-total",
			});
		},

		recadoPorId(id) {
			return instance({
				method: "get",
				url: "recado/" + id,
			});
		},

		adicionarRecado(recado) {
			return instance({
				method: "post",
				url: "recado",
				data: recado,
			});
		},

		aprovarDesaprovarRecado(recado) {
			return instance({
				method: "put",
				url: "recado",
				data: recado,
			});
		},

		removerRecado(id) {
			return instance({
				method: "delete",
				url: "recado/" + id,
			});
		},
	};
};
