<template>
	<div class="titulo-paginas">
		Convidados
		<a-button
			size="small"
			type="primary"
			shape="circle"
			@click="modalAdicionar = true"
		>
			<PlusOutlined />
		</a-button>

		<a-input
			v-model:value="pesquisar"
			placeholder="Pesquisar"
			style="margin-top: 10px"
		>
		</a-input>
	</div>

	<a-table
		:columns="columns"
		:data-source="dataVisivel"
		size="small"
		:loading="loading"
		rowKey="id"
	>
		<template #emptyText>
			<p>Nenhum presente cadastrado</p>
		</template>

		<template #bodyCell="{ column, record }">
			<template v-if="column.key === 'convidados'">
				{{ record.convidados.length }}
			</template>

			<template v-if="column.key === 'editar'">
				<a-button
					type="link"
					@click="editarTitular(record)"
					size="small"
				>
					<EditOutlined />
				</a-button>
			</template>

			<template v-else-if="column.key === 'remover'">
				<a-popconfirm
					title="Deseja realmente remover este titular e todos seus convidados?"
					ok-text="Sim"
					cancel-text="Não"
					@confirm="removerTitular(record.id)"
				>
					<a-button type="link" size="small">
						<DeleteOutlined />
					</a-button>
				</a-popconfirm>
			</template>

			<template v-if="column.key === 'ver'">
				<a-button
					type="link"
					@click="excluirTitular(record)"
					size="small"
				>
					<router-link
						:to="{
							name: 'visualizarConvidados',
							params: { id: record.id },
						}"
					>
						<SearchOutlined />
					</router-link>
				</a-button>
			</template>
		</template>
	</a-table>

	<a-modal
		v-model:visible="modalEditar"
		:title="'Alterar Titular ' + titularAlterar.nome"
		width="95%"
		@cancel="
			modalEditar = false;
			convidadoAlterar = { id: 0, nome: '' };
		"
	>
		<template #footer>
			<a-button @click="modalEditar = false"> Cancelar </a-button>
			<a-button type="primary" @click="salvarEdicao()"> Salvar </a-button>
		</template>

		<a-form :model="titularAlterar" layout="vertical">
			<a-form-item label="Nome">
				<a-input v-model:value="titularAlterar.nome" />
			</a-form-item>
		</a-form>
	</a-modal>

	<a-modal
		v-model:visible="modalAdicionar"
		title="Adicionar Titular"
		width="95%"
		@cancel="
			modalAdicionar = false;
			formState = { nome: '' };
		"
	>
		<template #footer>
			<a-button @click="modalAdicionar = false"> Cancelar </a-button>
			<a-button type="primary" @click="salvarTitular(formState)">
				Salvar
			</a-button>
		</template>

		<a-form :model="formState" layout="vertical">
			<a-form-item label="Nome">
				<a-input v-model:value="formState.nome" />
			</a-form-item>
		</a-form>
	</a-modal>
</template>

<script>
import {
	DeleteOutlined,
	EditOutlined,
	SearchOutlined,
	PlusOutlined,
	CheckOutlined,
	CloseOutlined,
} from "@ant-design/icons-vue";
import API from "@/api";

export default {
	components: {
		DeleteOutlined,
		EditOutlined,
		SearchOutlined,
		PlusOutlined,
		CheckOutlined,
		CloseOutlined,
	},
	data() {
		return {
			modalAdicionar: false,
			modalEditar: false,
			formState: {
				nome: "",
			},
			titularAlterar: {
				id: 0,
				nome: "",
			},
			pesquisar: "",
			loading: false,
			data: [],
			dataVisivel: [],
			columns: [
				{
					title: "Titular",
					dataIndex: "nome",
					key: "nome",
				},
				{
					title: "Quantidade",
					dataIndex: "convidados",
					key: "convidados",
					align: "center",
				},
				{
					title: "",
					key: "editar",
					width: "1px",
				},
				{
					title: "",
					key: "remover",
				},
				{
					title: "",
					key: "ver",
				},
			],
		};
	},

	watch: {
		pesquisar(value) {
			this.dataVisivel = this.data.filter((item) =>
				item.nome.toLowerCase().includes(value.toLowerCase())
			);
		},
	},

	mounted() {
		this.listarTitulares();
	},

	methods: {
		listarTitulares() {
			this.loading = true;

			API.listaTitulares()
				.then((response) => {
					this.data = response.data;
					this.dataVisivel = response.data;
				})
				.catch((error) => {
					console.error(error);
				})
				.finally(() => {
					this.loading = false;
				});
		},

		salvarTitular() {
			this.loading = true;

			API.adicionarTitular(this.formState)
				.then(() => {
					this.loading = false;
					this.modalAdicionar = false;
					this.formState = { nome: "" };

					this.$message.success("Titular adicionado com sucesso");

					this.listarTitulares();
				})
				.catch((error) => {
					console.error(error);
				});
		},

		editarTitular(titular) {
			this.modalEditar = true;
			this.titularAlterar.id = titular.id;
			this.titularAlterar.nome = titular.nome;
		},

		salvarEdicao() {
			this.loading = true;

			const titular = {
				id: this.titularAlterar.id,
				nome: this.titularAlterar.nome,
			};

			API.editarTitular(titular).then((response) => {
				this.loading = false;
				this.modalEditar = false;
				this.titularAlterar = { id: 0, nome: "" };

				this.$message.success("Titular editado com sucesso");

				this.listarTitulares();

				this.pesquisar = "";
			});
		},

		removerTitular(id) {
			this.loading = true;

			API.excluirTitular(id).then(() => {
				this.loading = false;

				this.$message.success("Titular removido com sucesso");

				this.listarTitulares();

				this.pesquisar = "";
			});
		},
	},
};
</script>

<style lang="less" scoped></style>
