<template>
	<div v-if="modoVisualizacao == 'mobile'">
		<img src="@/assets/images/background-2.png" class="imagem-fundo" />

		<a-spin :spinning="loading">
			<template #indicator>
				<div
					style="
						display: flex;
						justify-content: center;
						align-items: center;
					"
				>
					<img
						src="@/assets/images/carregamento.gif"
						style="width: 150px; height: auto"
					/>
				</div>
			</template>

			<div id="conteudo">
				<p id="texto-confirmar-presenca" class="font-montserrat">
					Querido convidado você pode <br />
					confirmar ou atualizar a sua <br />
					presença até o dia <b>30/09</b>.
				</p>

				<ul id="recados" class="font-montserrat">
					<li class="item">
						Lembresse o convite é individual e nominal cada um foi
						escolhido a dedo com muito carinho pra viver conosco
						esse dia importante.
					</li>
					<li class="item">
						Seu convite é intransferivel sem possibilidade de
						adições.
					</li>
					<li class="item">Convidado não convida!</li>
				</ul>

				<div class="font-montserrat">
					<button class="botao" @click="modalConfirmacao = true">
						CONFIRME PRESENÇA
					</button>
				</div>

				<div id="area-informacao-presenca" class="font-montserrat">
					<div id="palavra-atencao">ATENÇÃO</div>
					<span class="destaque">
						Só será permitida a entrada <br />
						mediante confirmação na lista
					</span>

					<br />
					SOB SUPERVISÃO NO LOCAL
				</div>
			</div>
		</a-spin>
	</div>

	<div v-else>
		<div>
			<a-drawer
				placement="left"
				:visible="visible"
				@close="visible = false"
				class="desktop-menu"
				width="20%"
			>
				<template #closeIcon>
					<MenuOutlined id="icone-fechar-menu" class="texto-branco" />
				</template>

				<template #extra>
					<a-button
						style="margin-right: 8px"
						@click="irParaAdmin()"
						v-if="usuarioAutenticado.perfil == 'ADMIN'"
					>
						Admin
					</a-button>
				</template>

				<nav id="menu-navegacao">
					<router-link
						:to="{ name: 'home' }"
						class="lista-item"
						@click="visible = false"
					>
						<div class="texto-branco lista-item font-custom">
							<span class="item-menu"> TELA INICIAL </span>
							<RightOutlined class="icone-menu" />
						</div>
					</router-link>

					<hr class="separador-menu-desktop" />

					<router-link
						:to="{ name: 'convite' }"
						class="lista-item"
						@click="visible = false"
					>
						<div class="texto-branco lista-item font-custom">
							<span class="item-menu"> O CONVITE </span>
							<RightOutlined class="icone-menu" />
						</div>
					</router-link>

					<hr class="separador-menu-desktop" />

					<router-link
						:to="{ name: 'detalhes' }"
						class="lista-item"
						@click="visible = false"
					>
						<div class="texto-branco lista-item font-custom">
							<span class="item-menu"> O CASAMENTO </span>
							<RightOutlined class="icone-menu" />
						</div>
					</router-link>

					<hr class="separador-menu-desktop" />

					<router-link
						:to="{ name: 'confirmacao' }"
						class="lista-item"
						@click="visible = false"
					>
						<div class="texto-branco lista-item font-custom">
							<span class="item-menu"> CONFIRMAR PRESENÇA </span>
							<RightOutlined class="icone-menu" />
						</div>
					</router-link>

					<hr class="separador-menu-desktop" />

					<router-link
						:to="{ name: 'recados' }"
						class="lista-item"
						@click="visible = false"
					>
						<div class="texto-branco lista-item font-custom">
							<span class="item-menu"> DEIXE SEU RECADO </span>
							<RightOutlined class="icone-menu" />
						</div>
					</router-link>

					<hr class="separador-menu-desktop" />

					<router-link
						:to="{ name: 'presentes' }"
						class="lista-item"
						@click="visible = false"
					>
						<div class="texto-branco lista-item-presente">
							DESEJA NOS PRESENTEAR ?
						</div>
					</router-link>

					<div @click="sair()" id="area-sair">
						<span class="texto-branco font-custom"> SAIR </span>
					</div>
				</nav>

				<div style="text-align: center">
					<img
						src="@/assets/images/logo-dj.png"
						:style="
							modoVisualizacao == 'mobile'
								? 'width: 80%'
								: 'width: 50%'
						"
					/>
				</div>
			</a-drawer>
			<div id="area-botao-menu-desktop">
				<a-button
					shape="circle"
					:id="
						modoVisualizacao == 'mobile'
							? 'botao-menu'
							: 'botao-menu-desktop'
					"
					@click="visible = true"
				>
					<MenuOutlined />
				</a-button>

				<div id="barra-menu-desktop"></div>

				<div id="titulo-pagina-desktop">
					<div id="titulo-1" class="font-montserrat">
						{{ tituloPagina()[0] }}
					</div>
					<div id="titulo-2">{{ tituloPagina()[1] }}</div>
				</div>
			</div>
		</div>

		<img src="@/assets/images/fundo-desktop.png" class="imagem-fundo" />

		<div id="area-confirmacao-desktop">
			<p id="texto-confirmar-presenca-desktop" class="font-montserrat">
				Querido convidado você pode <br />
				confirmar ou atualizar a sua <br />
				presença até o dia <b>30/09</b>.
			</p>

			<ul id="recados-desktop" class="font-montserrat">
				<li class="item-desktop">
					Lembresse o convite é individual e nominal cada um foi
					escolhido a dedo com muito carinho pra viver conosco esse
					dia importante.
				</li>
				<li class="item-desktop">
					Seu convite é intransferivel sem possibilidade de adições.
				</li>
				<li class="item-desktop">Convidado não convida!</li>
			</ul>

			<div class="font-montserrat">
				<button
					class="botao-desktop"
					@click="modalConfirmacaoDesktop = true"
				>
					CONFIRME PRESENÇA
				</button>
			</div>

			<img
				src="@/assets/images/logo-jd-rosa.png"
				style="width: 10%; margin-top: 10px"
			/>

			<div id="area-informacao-presenca-desktop" class="font-montserrat">
				<div id="palavra-atencao-desktop">ATENÇÃO</div>
				<div class="destaque-desktop">
					Só será permitida a entrada <br />
					mediante confirmação na lista
				</div>

				<br />
				SOB SUPERVISÃO NO LOCAL
			</div>
		</div>
	</div>

	<a-modal
		v-model:visible="modalConfirmacao"
		title="Clique para confirmar a presença"
		width="95%"
		style="top: 25%"
		:footer="null"
	>
		<a-list class="demo-loadmore-list" item-layout="horizontal">
			<template
				v-for="convidado in usuarioAutenticado.convidados"
				:key="convidado.nome"
			>
				<a-list-item>
					{{ convidado.nome }}

					<a-typography-text
						style="font-size: 11px"
						type="secondary"
						v-if="!convidado.confirmado"
					>
						- Não confirmado
					</a-typography-text>

					<a-typography-text
						style="font-size: 11px"
						type="secondary"
						v-else
					>
						- Confirmado
					</a-typography-text>

					<span key="list-loadmore-edit">
						<CheckOutlined
							style="color: green"
							v-if="convidado.confirmado"
						/>
					</span>

					<template #actions>
						<a-popconfirm
							title="Deseja confirmar presença?"
							ok-text="Sim"
							cancel-text="Não"
							@confirm="confirmarPresenca(convidado)"
							v-if="!convidado.confirmado"
						>
							<!-- <CheckOutlined
									class="check-convidados-confirmar"
								/> -->
							<a-tag
								color="green"
								style="font-size: 11px; width: 63px"
							>
								Confirmar
							</a-tag>
						</a-popconfirm>

						<a-popconfirm
							title="Deseja remover a confirmação?"
							ok-text="Sim"
							cancel-text="Não"
							@confirm="removerConfirmacaoPresenca(convidado)"
							v-else
						>
							<a-tag
								color="red"
								style="font-size: 11px; width: 63px"
							>
								Remover
							</a-tag>
						</a-popconfirm>
					</template>
				</a-list-item>
			</template>
		</a-list>
	</a-modal>

	<a-modal
		v-model:visible="modalConfirmacaoDesktop"
		title="Clique para confirmar a presença"
		width="40%"
		style="top: 25%"
		:footer="null"
	>
		<a-list class="demo-loadmore-list" item-layout="horizontal">
			<template
				v-for="convidado in usuarioAutenticado.convidados"
				:key="convidado.nome"
			>
				<a-list-item style="font-size: 16px">
					{{ convidado.nome }}

					<a-typography-text
						style="font-size: 13px"
						type="secondary"
						v-if="!convidado.confirmado"
					>
						- Não confirmado
					</a-typography-text>

					<a-typography-text
						style="font-size: 13px"
						type="secondary"
						v-else
					>
						- Confirmado
					</a-typography-text>

					<span key="list-loadmore-edit">
						<CheckOutlined
							style="color: green"
							v-if="convidado.confirmado"
						/>
					</span>

					<template #actions>
						<a-popconfirm
							title="Deseja confirmar presença?"
							ok-text="Sim"
							cancel-text="Não"
							@confirm="confirmarPresenca(convidado)"
							v-if="!convidado.confirmado"
						>
							<!-- <CheckOutlined
									class="check-convidados-confirmar"
								/> -->
							<a-tag
								color="green"
								style="
									font-size: 11px;
									width: 63px;
									cursor: pointer;
								"
							>
								Confirmar
							</a-tag>
						</a-popconfirm>

						<a-popconfirm
							title="Deseja remover a confirmação?"
							ok-text="Sim"
							cancel-text="Não"
							@confirm="removerConfirmacaoPresenca(convidado)"
							v-else
						>
							<a-tag
								color="red"
								style="
									font-size: 11px;
									width: 63px;
									cursor: pointer;
								"
							>
								Remover
							</a-tag>
						</a-popconfirm>
					</template>
				</a-list-item>
			</template>
		</a-list>
	</a-modal>
</template>

<script>
import {
	MenuOutlined,
	RightOutlined,
	CheckOutlined,
	CloseOutlined,
} from "@ant-design/icons-vue";
import Menu from "@/components/MenuComponent.vue";
import API from "@/api";
import { notification } from "ant-design-vue";

export default {
	components: {
		MenuOutlined,
		RightOutlined,
		CheckOutlined,
		CloseOutlined,
		Menu,
	},
	data() {
		return {
			visible: false,
			depois: [],
			modalConfirmacao: false,
			modalConfirmacaoDesktop: false,
			windowHeight: 0,
			windowWidth: 0,
			confirmados: [],
			loading: true,
			modoVisualizacao: "",
		};
	},

	computed: {
		usuarioAutenticado() {
			return this.$store.getters.getUser;
		},
	},

	watch: {
		windowWidth(val) {
			this.modoVisualizacao =
				this.windowHeight > this.windowWidth ? "mobile" : "desktop";
		},
	},

	methods: {
		irParaAdmin() {
			this.$router.push({ name: "homeAdmin" });
		},

		sair() {
			this.$store.dispatch("logout");
			this.$router.push({ name: "entrar" });
		},

		resizeHandler(e) {
			this.windowHeight = window.innerHeight;
			this.windowWidth = window.innerWidth;
		},

		confirmarPresenca(convidado) {
			API.confirmarOuDesconfirmarConvidado(convidado).then(() => {
				const convidadosAtualizados =
					this.usuarioAutenticado.convidados.map((convidadoAtual) => {
						if (convidadoAtual.nome === convidado.nome) {
							convidadoAtual.confirmado =
								!convidadoAtual.confirmado;
						}

						return convidadoAtual;
					});

				this.$store.dispatch(
					"atualizarConfirmacaoConvidado",
					convidadosAtualizados
				);

				notification.success({
					message: "Presença confirmada",
					description:
						"Estamos felizes pela confirmação. Contamos com sua presença!",
					duration: 3,
				});
			});
		},

		removerConfirmacaoPresenca(convidado) {
			API.confirmarOuDesconfirmarConvidado(convidado).then(() => {
				const convidadosAtualizados =
					this.usuarioAutenticado.convidados.map((convidadoAtual) => {
						if (convidadoAtual.nome === convidado.nome) {
							convidadoAtual.confirmado = false;
						}

						return convidadoAtual;
					});

				this.$store.dispatch(
					"atualizarConfirmacaoConvidado",
					convidadosAtualizados
				);

				notification.warning({
					message: "Confirmação removida",
					description: "Que pena, mas agradeçemos por nos avisar!",
					duration: 3,
				});
			});
		},

		tituloPagina() {
			switch (this.$route.name) {
				case "home":
					return ["", ""];
				case "detalhes":
					return ["CASAMENTO", "DETALHES"];
				case "recados":
					return ["DEIXE SEU", "RECADO"];
				case "confirmacao":
					return ["CONFIRME", "PRESENÇA"];
				case "presentes":
					return ["LISTA DE", "PRESENTES"];
				case "confirmarPresenca":
					return ["CONFIRMAR", "PRESENÇA"];
				default:
					return ["", ""];
			}
		},
	},

	created() {
		window.addEventListener("resize", this.resizeHandler);
	},

	destroyed() {
		window.removeEventListener("resize", this.resizeHandler);
	},

	mounted() {
		this.windowHeight = window.innerHeight;
		this.windowWidth = window.innerWidth;

		setTimeout(() => {
			this.loading = false;
		}, 1000);
	},
};
</script>

<style lang="less" scoped>
#area-confirmacao-desktop {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 80vh;
}

#conteudo-confirmar {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

#conteudo {
	margin-top: 10%;
	height: 60vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

#texto-confirmar-presenca {
	font-weight: 400;
	letter-spacing: 1.5px;
	font-size: 14px;
	text-transform: uppercase;
	line-height: 16px;
	text-align: justify;
	padding: 0 20px;
}

#texto-confirmar-presenca-desktop {
	font-weight: 700;
	letter-spacing: 1.5px;
	font-size: 22px;
	text-transform: uppercase;
	line-height: 25px;
	text-align: justify;
	padding: 0 20px;
}

.botao {
	border-radius: 24px;
	padding: 10px 20px;
	font-size: 11px;
	line-height: 22px;
	background-color: #fdd6e7;
	color: #644f44;
	border: none;
	cursor: pointer;
}

.botao-desktop {
	border-radius: 24px;
	padding: 15px 25px;
	font-size: 14px;
	line-height: 22px;
	background-color: #fdd6e7;
	color: #644f44;
	border: none;
	cursor: pointer;
}

#area-informacao-presenca {
	font-weight: 300;
	letter-spacing: 1px;
	font-size: 11px;
	text-transform: uppercase;
	line-height: 17px;
	text-align: center;
	margin-top: 20%;
}

#area-informacao-presenca-desktop {
	font-weight: 300;
	letter-spacing: 1px;
	text-transform: uppercase;
	line-height: 17px;
	text-align: center;
	margin-top: 30px;
}

.destaque {
	font-weight: 700;
}

.destaque-desktop {
	font-weight: 700;
	font-size: 12px;
}

#palavra-atencao {
	margin-bottom: 10px;
	letter-spacing: 3px;
	font-size: 14px;
}

#palavra-atencao-desktop {
	margin-bottom: 10px;
	letter-spacing: 3px;
	font-size: 16px;
	font-weight: 700;
}

#recados {
	width: 75%;
	font-weight: 400;
	font-size: 10px;
	text-transform: uppercase;
	line-height: 13px;
	letter-spacing: 1.5px;
	margin-bottom: 30px;
	text-align: justify;
}

#recados-desktop {
	width: 400px;
	font-weight: 500;
	font-size: 12px;
	text-transform: uppercase;
	line-height: 18px;
	letter-spacing: 1px;
	margin-bottom: 30px;
	text-align: justify;
}

.item {
	margin-bottom: 3px;
	margin-left: -30px;
}

.item-desktop {
	margin-bottom: 5px;
	margin-left: -30px;
}

.check-convidados-confirmar {
	color: #d4b4d3;
	border-radius: 50%;
	background-color: #fff;
	border: none;
	padding: 5px;
	margin-top: -10px;
	border: 1px solid #d4b4d3;
}

.check-convidados-remover {
	color: #d4b4d3;
	border-radius: 50%;
	background-color: #fff;
	border: none;
	padding: 5px;
	margin-top: -10px;
	border: 1px solid #d4b4d3;
}

.lista-item {
	width: 100%;
	font-weight: bolder;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	.item-menu {
		font-size: 1.3em;
		width: 70%;
		line-height: 1em;
		cursor: pointer;
		padding: 10px 20px;
	}

	.icone-menu {
		color: #6b604e;
		font-weight: bolder;
	}
}

#area-sair {
	padding-top: 20px;
	cursor: pointer;
	user-select: none;
	font-size: 2em;
}

.separador-menu-desktop {
	border: none;
}

.lista-item-presente {
	padding-top: 10px;
	font-size: 10px;
	font-weight: 400;
	letter-spacing: 1px;
}

#titulo-pagina-desktop {
	margin-left: 10px;

	#titulo-1 {
		font-size: 17px;
		line-height: 0px;
		color: #fff;
		letter-spacing: 1.5px;
	}

	#titulo-2 {
		font-size: 42px;
		line-height: 50px;
		font-weight: bold;
		color: #fff;
	}
}
</style>
