export default ({ instance }) => {
	return {
		loginAdministrador(login) {
			return instance({
				method: "post",
				url: "administrador",
				data: login,
			});
		},

		// Convite
		listarConvites() {
			return instance({
				method: "get",
				url: "convite/todos-convites",
			});
		},
	};
};
