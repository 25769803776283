import { createRouter, createWebHistory } from "vue-router";
import EntrarView from "../views/EntrarView.vue";
import HomeView from "../views/HomeView.vue";
import ConviteView from "../views/ConviteView.vue";
import ConfirmarView from "../views/ConfirmarView.vue";
import DetalhesView from "../views/DetalhesView.vue";
import presentesView from "../views/PresentesView.vue";
import RecadosView from "../views/RecadosView.vue";
import ConfrmarPresenca from "../views/ConfirmarPresencaView.vue";
import LayoutPrincipal from "../layout/LayoutPrincipal.vue";
import PresentearView from "../views/FinalizarCompraView.vue";
import PagamentoRealizado from "../views/ConfirmacaoPagamentoView.vue";

//ADMINISTRATIVO
import LayoutAdministrativo from "../layout/LayoutAdministrativo.vue";
import LoginAdmnin from "../views/Administrativo/LoginAdministrativoView.vue";
import HomeAdmin from "../views/Administrativo/HomeAdministrativoView.vue";
import ConviteAdmin from "../views/Administrativo/ConviteAdministrativoView.vue";
import RecadosAdmin from "../views/Administrativo/RecadosAdministrativoView.vue";
import PresentesAdmin from "../views/Administrativo/PresentesAdministrativoView.vue";
import ConvidadosAdmin from "../views/Administrativo/ConvidadosAdministrativoView.vue";
import ConfirmadosAdmin from "../views/Administrativo/ConfirmadosAdministrativoView.vue";

//CADASTRO DE PRESENTES
import NovoPresenteAdmin from "../views/Administrativo/TelasCadastro/CadastroPresenteView.vue";
import VisualizarPresenteAdmin from "../views/Administrativo/TelasVisualizacao/VisualizarPresenteView.vue";

//CONVIDADOS
import VisualizarConvidadosAdmin from "../views/Administrativo/TelasVisualizacao/VisualizarConvidadosView.vue";

//CADASTRO CONVITE
import NovoConviteAdmin from "../views/Administrativo/TelasCadastro/CadastroConviteView.vue";

import store from "@/store";

const routes = [
	{
		path: "/",
		name: "entrar",
		component: EntrarView,
	},
	{
		path: "/presentear/:id",
		name: "finalizarCompra",
		component: PresentearView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/pagamento-realizado/:id",
		name: "pagamentoRealizado",
		component: PagamentoRealizado,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/home",
		name: "bemvindo",
		component: LayoutPrincipal,
		meta: {
			requiresAuth: true,
		},
		children: [
			{
				path: "/home",
				name: "home",
				component: HomeView,
			},
			{
				path: "/convite",
				name: "convite",
				component: ConviteView,
			},
			{
				path: "/confirmacao",
				name: "confirmacao",
				component: ConfirmarView,
			},
			{
				path: "/recados",
				name: "recados",
				component: RecadosView,
			},
			{
				path: "/presentes",
				name: "presentes",
				component: presentesView,
			},
			{
				path: "/detalhes",
				name: "detalhes",
				component: DetalhesView,
			},
			{
				path: "/confirmar-presenca",
				name: "confirmarPresenca",
				component: ConfrmarPresenca,
			},
		],
	},
	{
		path: "/admin",
		name: "admin",
		component: LayoutAdministrativo,
		children: [
			{
				path: "/admin/home",
				name: "homeAdmin",
				component: HomeAdmin,
				meta: {
					requiresAuthAdmin: true,
					painelAdmin: true,
				},
			},
			{
				path: "/admin/convite",
				name: "conviteAdmin",
				component: ConviteAdmin,
				meta: {
					requiresAuthAdmin: true,
					painelAdmin: true,
				},
			},
			{
				path: "/admin/recados/:status?",
				name: "recadosAdmin",
				component: RecadosAdmin,
				meta: {
					requiresAuthAdmin: true,
					painelAdmin: true,
				},
			},
			{
				path: "/admin/presentes",
				name: "presentesAdmin",
				component: PresentesAdmin,
				meta: {
					requiresAuthAdmin: true,
					painelAdmin: true,
				},
			},
			{
				path: "/admin/convidados",
				name: "convidadosAdmin",
				component: ConvidadosAdmin,
				meta: {
					requiresAuthAdmin: true,
					painelAdmin: true,
				},
			},
			{
				path: "/admin/confirmados",
				name: "confirmadosAdmin",
				component: ConfirmadosAdmin,
				meta: {
					requiresAuthAdmin: true,
					painelAdmin: true,
				},
			},
		],
	},

	{
		path: "/admin/presentes/cadastrar",
		name: "cadastrarPresente",
		component: NovoPresenteAdmin,
		meta: {
			requiresAuthAdmin: true,
			painelAdmin: true,
		},
	},
	{
		path: "/admin/convites/cadastrar",
		name: "cadastrarConvite",
		component: NovoConviteAdmin,
		meta: {
			requiresAuthAdmin: true,
			painelAdmin: true,
		},
	},
	{
		path: "/admin/presentes/visualizar/:id",
		name: "visualizarPresente",
		component: VisualizarPresenteAdmin,
		meta: {
			requiresAuthAdmin: true,
			painelAdmin: true,
		},
	},
	{
		path: "/admin/convidados/visualizar/:id",
		name: "visualizarConvidados",
		component: VisualizarConvidadosAdmin,
		meta: {
			requiresAuthAdmin: true,
			painelAdmin: true,
		},
	},

	{
		path: "/login-administrativo",
		name: "loginAdministrativo",
		component: LoginAdmnin,
		meta: {
			requiresAuthAdmin: false,
			painelAdmin: true,
		},
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.meta.painelAdmin) {
		if (to.meta.requiresAuthAdmin && store.getters.getTokenAdm == null) {
			next({
				name: "loginAdministrativo",
			});
		} else if (
			to.name === "loginAdministrativo" &&
			store.getters.getTokenAdm != null
		) {
			next({ name: "homeAdmin" });
		} else {
			next();
		}
	} else {
		if (to.meta.requiresAuth && !store.getters.getAuth) {
			next({
				name: "entrar",
			});
		} else if (to.name === "entrar" && store.getters.getAuth) {
			next({ name: "home" });
		} else {
			next();
		}
	}
});

export default router;
