export default ({ instance }) => {
	return {
		listarConvites() {
			return instance({
				method: "get",
				url: "convite",
			});
		},

		quantidadeConvites() {
			return instance({
				method: "get",
				url: "convite/quantidade-convites",
			});
		},

		convitePorId(id) {
			return instance({
				method: "get",
				url: "convite/" + id,
			});
		},

		adicionarConvite(convite) {
			return instance({
				method: "post",
				url: "convite",
				data: convite,
			});
		},

		editarConvite(convite) {
			return instance({
				method: "put",
				url: "convite",
				data: convite,
			});
		},

		removerConvite(id) {
			return instance({
				method: "delete",
				url: "convite/" + id,
			});
		},

		entrarConvite(senha) {
			return instance({
				method: "post",
				url: "convite/entrar-convite/" + senha,
			});
		},
	};
};
