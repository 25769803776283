<template>
	<a-spin :spinning="loading">
		<div id="area">
			<a-form
				:rules="rules"
				layout="horizontal"
				:model="formState"
				@finish="salvarAlteracao"
			>
				<a-form-item label="Nome" name="nome">
					<a-input
						v-model:value="formState.nome"
						:disabled="!editar"
					/>
				</a-form-item>

				<a-row type="flex" justify="space-between">
					<a-col :span="14">
						<a-form-item
							label="Preço"
							name="preco"
							class="form-item"
						>
							<a-input
								v-model:value="formState.preco"
								:disabled="!editar"
							/>
						</a-form-item>
					</a-col>
					<a-col :span="8">
						<a-form-item
							class="form-item"
							label="Relevância"
							name="relevancia"
						>
							<a-input-number
								v-model:value="formState.relevancia"
								:disabled="!editar"
								style="width: 100%"
								min="0"
							/>
						</a-form-item>
					</a-col>
				</a-row>

				<a-form-item
					label="Descrição"
					class="form-item"
					name="descricao"
				>
					<a-input
						v-model:value="formState.descricao"
						:disabled="!editar"
					/>
				</a-form-item>

				<a-form-item
					label="Categoria"
					class="form-item"
					name="categoriaPresenteId"
				>
					<a-select
						v-model:value="formState.categoriaPresenteId"
						:disabled="!editar"
						placeholder="Selecione uma categoria"
					>
						<template
							v-for="categoria in categorias"
							:key="categoria.id"
						>
							<a-select-option :value="categoria.id">
								{{ categoria.nome }}
							</a-select-option>
						</template>
					</a-select>
				</a-form-item>

				<a-form-item label="Link" class="form-item" name="link">
					<a-input
						v-model:value="formState.link"
						:disabled="!editar"
					/>
				</a-form-item>

				<a-row type="flex" justify="space-between">
					<a-col :span="12">
						<a-form-item label="" v-if="!editar">
							<img
								alt="example"
								style="width: 150px"
								:src="formState.imagem"
							/>
						</a-form-item>

						<a-form-item label="" v-if="editar" name="imagem">
							<a-upload
								v-model:file-list="fileList"
								list-type="picture-card"
								@preview="handlePreview"
								class="upload-no-border"
								:disabled="!editar"
								:capture="null"
								accept="image/png,image/jpeg,image/jpg"
							>
								<div v-if="fileList.length < 1">
									<plus-outlined />
									<div style="margin-top: 8px">
										Carregar imagem
									</div>
								</div>
							</a-upload>
						</a-form-item>
					</a-col>

					<a-col :span="8">
						<a-form-item label="Disponível">
							<a-switch v-model:checked="formState.disponivel" />
						</a-form-item>
					</a-col>
				</a-row>

				<a-row type="flex" justify="end">
					<a-form-item style="margin-right: 10px">
						<router-link :to="{ name: 'presentesAdmin' }">
							<a-button> Voltar </a-button>
						</router-link>
					</a-form-item>

					<a-form-item v-if="editar" style="margin-right: 10px">
						<a-button @click="editar = !editar">
							Cancelar
						</a-button>
					</a-form-item>

					<a-form-item style="margin-right: 10px" v-if="!editar">
						<a-button
							type="primary"
							:loading="loading"
							@click="editar = !editar"
						>
							<EditOutlined />
						</a-button>
					</a-form-item>

					<a-form-item style="margin-right: 10px" v-else>
						<a-button
							type="primary"
							:loading="loading"
							html-type="submit"
						>
							<SaveOutlined />
						</a-button>
					</a-form-item>

					<a-form-item>
						<a-popconfirm
							title="Deseja realmente excluir este presente?"
							ok-text="Sim"
							cancel-text="Não"
							@confirm="excluirPresente"
						>
							<a-button type="danger" :loading="loading">
								<DeleteOutlined />
							</a-button>
						</a-popconfirm>
					</a-form-item>
				</a-row>
			</a-form>
		</div>
	</a-spin>

	<a-modal
		:visible="previewVisible"
		:title="previewTitle"
		:footer="null"
		@cancel="previewVisible = false"
	>
		<img alt="example" style="width: 100%" :src="previewImage" />
	</a-modal>
</template>

<script>
import API from "@/api";
import {
	DeleteOutlined,
	EditOutlined,
	SaveOutlined,
	PlusOutlined,
} from "@ant-design/icons-vue";

export default {
	components: {
		DeleteOutlined,
		EditOutlined,
		SaveOutlined,
		PlusOutlined,
	},

	data() {
		return {
			fileList: [],
			previewVisible: false,
			previewImage: "",
			previewTitle: "",
			loading: false,
			formState: {},
			editar: false,
			categorias: [],
			rules: {
				nome: [
					{
						required: true,
						message: "",
					},
				],
				preco: [
					{
						required: true,
						message: "",
					},
				],
				descricao: [
					{
						required: true,
						message: "",
					},
				],
				categoriaPresenteId: [
					{
						required: true,
						message: "",
					},
				],
				link: [
					{
						required: true,
						message: "",
					},
				],
				imagem: [
					{
						required: true,
						message: "Insira imagem",
					},
				],
				relevancia: [
					{
						required: true,
						message: "",
					},
				],
			},
		};
	},

	watch: {
		fileList: {
			handler(newVal, oldVal) {
				if (newVal.length > 0) {
					const firstFile = newVal[0];
					if (firstFile.thumbUrl) {
						this.formState.imagem = firstFile.thumbUrl;
					}
				}
			},
			deep: true,
		},

		"formState.disponivel"(value, oldValue) {
			if (oldValue != undefined) {
				this.editarPresente();
			}
		},
	},

	mounted() {
		this.listarCategoriasPresente();
		this.consultarPresentePorId();
	},

	methods: {
		async handlePreview(file) {
			if (!file.url && !file.preview) {
				try {
					const preview = await this.getBase64(file.originFileObj);
					file.preview = preview;
					this.previewImage = preview; // Update previewImage with base64 directly
					this.previewVisible = true;
					this.previewTitle =
						file.name ||
						file.url.substring(file.url.lastIndexOf("/") + 1);
				} catch (error) {
					console.error("Error generating preview:", error);
				}
			} else {
				this.previewImage = file.url || file.preview;
				this.previewVisible = true;
				this.previewTitle =
					file.name ||
					file.url.substring(file.url.lastIndexOf("/") + 1);
			}
		},

		getBase64(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => resolve(reader.result);
				reader.onerror = (error) => reject(error);
			});
		},

		salvarAlteracao() {
			if (this.editar) {
				this.editarPresente();
			} else {
				this.editar = true;
			}
		},

		consultarPresentePorId() {
			this.loading = true;

			API.consultarPresentePorId(this.$route.params.id).then(
				(response) => {
					this.formState = response.data;

					this.loading = false;
				}
			);
		},

		editarPresente() {
			this.loading = true;

			API.editarPresente(this.formState).then((response) => {
				this.loading = false;
				this.editar = false;

				this.$message.success("Presente alterado com sucesso");

				this.consultarPresentePorId();

				this.loading = false;
			});
		},

		excluirPresente() {
			this.loading = true;

			API.excluirPresente(this.$route.params.id).then((response) => {
				this.loading = false;

				this.$message.success("Presente excluído com sucesso");

				this.$router.push({ name: "presentesAdmin" });

				this.loading = false;
			});
		},

		listarCategoriasPresente() {
			API.consultarCategoriasPresente().then((response) => {
				this.categorias = response.data;
			});
		},
	},
};
</script>

<style lang="less" scoped>
#area {
	padding: 10px;
}

.imagem-presente {
	object-fit: cover;
	height: 150px;
}

.ant-upload-select-picture-card i {
	font-size: 32px;
	color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
	margin-top: 8px;
	color: #666;
}

:deep(.ant-upload-list-picture-card .ant-upload-list-item-error) {
	border: none !important;
}

.form-item {
	margin-top: -20px;
}
</style>
