<template>
	<img src="@/assets/images/background-2.png" class="imagem-fundo" />

	<a-spin :spinning="loading">
		<template #indicator>
			<div
				style="
					display: flex;
					justify-content: center;
					align-items: center;
				"
			>
				<img
					src="@/assets/images/carregamento.gif"
					style="width: 150px; height: auto"
				/>
			</div>
		</template>

		<div id="conteudo">
			<p id="texto-pagamento-presenca" class="font-montserrat">
				Pagamento Confirmado!
			</p>

			<p id="recados" class="font-montserrat">
				Obrigado por nos ajudar a tornar esse dia ainda mais especial.
			</p>

			<p id="recados" class="font-montserrat">
				Agora você pode ir na tela de
				<router-link :to="{ name: 'recados' }"> recados </router-link> e
				deixar uma mensagem para nós.
			</p>

			<div id="area-botao-confirmar" class="font-montserrat">
				<router-link :to="{ name: 'home' }">
					<button class="botao">RETORNAR AO CONVITE</button>
				</router-link>
			</div>
		</div>
	</a-spin>
</template>

<script>
import API from "@/api";

export default {
	data() {
		return {
			loading: false,
			idPresenteComprado: 0,
			presente: {},
		};
	},

	computed: {
		usuarioAutenticado() {
			return this.$store.getters.getUser;
		},
	},

	created() {
		this.idPresenteComprado = this.$route.params.id;
		this.consultarPresentePorId(this.$route.params.id);
	},

	methods: {
		consultarPresentePorId(value) {
			this.loading = true;

			API.consultarPresentePorId(value).then((response) => {
				this.presente = response.data;

				if (!this.presente.disponivel) {
					this.$router.push({ name: "home" });
				} else {
					this.presente.disponivel = false;
					this.presente.comprado = true;
					this.presente.conviteId = this.usuarioAutenticado.id;

					this.atualizarPresenteParaComprado(this.presente);
				}
			});
		},

		atualizarPresenteParaComprado(value) {
			console.log(value);
			API.editarPresente(value)
				.then((response) => {})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style lang="less" scoped>
#conteudo {
	height: 70vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

#texto-pagamento-presenca {
	font-weight: 600;
	letter-spacing: 1.5px;
	font-size: 18px;
	text-transform: uppercase;
	line-height: 16px;
	text-align: center;
	padding: 0 20px;
}

.botao {
	margin-top: 50px;
	border-radius: 24px;
	padding: 10px 20px;
	font-size: 11px;
	line-height: 22px;
	background-color: #fdd6e7;
	color: #644f44;
	border: none;
	cursor: pointer;
}

#recados {
	width: 75%;
	font-weight: 400;
	font-size: 14px;
	line-height: 13px;
	letter-spacing: 1.5px;
	text-align: center;
}
</style>
