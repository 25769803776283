<template>
	<a-drawer
		placement="left"
		:visible="true"
		@close="visible = false"
		class="menu"
		width="60%"
	>
		<template #closeIcon>
			<MenuOutlined id="icone-fechar-menu" class="texto-branco" />
		</template>

		<nav id="menu-navegacao">
			<div class="texto-branco lista-item font-custom">
				<span class="item-menu"> {{ status }} </span>
				<RightOutlined class="icone-menu" />
			</div>

			<hr class="separador-menu" />

			<div class="texto-branco lista-item font-custom">
				<span class="item-menu"> O CONVITE </span>
				<RightOutlined class="icone-menu" />
			</div>

			<hr class="separador-menu" />

			<div class="texto-branco lista-item font-custom">
				<span class="item-menu"> O CASAMENTO </span>
				<RightOutlined class="icone-menu" />
			</div>

			<hr class="separador-menu" />

			<div class="texto-branco lista-item font-custom">
				<span class="item-menu"> CONFIRMAR PRESENÇA </span>
				<RightOutlined class="icone-menu" />
			</div>

			<hr class="separador-menu" />

			<div class="texto-branco lista-item font-custom">
				<span class="item-menu"> DEIXE SEU RECADO </span>
				<RightOutlined class="icone-menu" />
			</div>

			<hr class="separador-menu" />

			<div class="texto-branco lista-item-presente">
				DESEJA NOS PRESENTEAR ?
			</div>
		</nav>
		<div></div>

		<div style="text-align: center">
			<img src="@/assets/images/logo-dj.png" style="width: 80%" />
		</div>
	</a-drawer>
</template>

<script>
import { MenuOutlined, RightOutlined } from "@ant-design/icons-vue";

export default {
	name: "MenuComponent",
	components: {
		MenuOutlined,
		RightOutlined,
	},
	props: ["status"],
	data() {
		return {
			visible: false,
		};
	},
};
</script>

<style lang="less" scoped>
.lista-item:hover {
	-webkit-animation: swing 1s ease;
	animation: anime-balanco 1s ease;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
	color: #c2a6c1;
}

.separador {
	border-bottom: 2px dotted #fff;
	width: 100%;
}

.lista-item {
	border-bottom: 2px dotted #fff;
	width: 100%;
	font-size: 1em;
	padding-left: 5px;
	cursor: pointer;
}

#menu {
	display: flex;
	justify-content: center;
	align-items: center;
}

@keyframes anime-balanco {
	15% {
		-webkit-transform: translateX(7px);
		transform: translateX(7px);
	}
	30% {
		-webkit-transform: translateX(-7px);
		transform: translateX(-7px);
	}
	50% {
		-webkit-transform: translateX(3px);
		transform: translateX(3px);
	}
	65% {
		-webkit-transform: translateX(-3px);
		transform: translateX(-3px);
	}
	80% {
		-webkit-transform: translateX(2px);
		transform: translateX(2px);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}
</style>
