<template>
	<a-row type="flex" justify="center" align="center" id="formulario-login">
		<a-col :span="22">
			<a-form :model="formState" @finish="loginAdministrador">
				<a-form-item
					label="Login"
					name="login"
					class="input-login"
					:rules="[{ required: true, message: 'Insira o Login!' }]"
				>
					<a-input v-model:value="formState.login" />
				</a-form-item>

				<a-form-item
					label="Senha"
					name="senha"
					class="input-login"
					:rules="[{ required: true, message: 'Insira a Senha!' }]"
				>
					<a-input-password v-model:value="formState.senha" />
				</a-form-item>

				<a-form-item style="text-align: right">
					<a-button type="primary" html-type="submit">
						Login
					</a-button>
				</a-form-item>
			</a-form>
		</a-col>
	</a-row>
</template>

<script>
import API from "@/api";

export default {
	data() {
		return {
			formState: {
				login: "",
				senha: "",
			},
		};
	},
	methods: {
		loginAdministrador() {
			API.loginAdministrador(this.formState)
				.then((response) => {
					this.$store.dispatch("loginAdm", {
						token: response.data.token,
						nome: response.data.nome,
						login: response.data.login,
					});

					this.$router.push({ name: "homeAdmin" });
				})
				.catch((error) => {
					if (error.response?.status == 401) {
						this.$message.error("Login ou senha incorretos!");
					} else {
						this.$message.error("Erro ao realizar login!");
					}
				});
		},
	},
};
</script>

<style scoped lang="less">
#formulario-login {
	margin-top: 40%;
}

.input-login {
	width: 100%;
}
</style>
