<template>
	<div class="titulo-paginas">
		Convites

		<router-link :to="{ name: 'cadastrarConvite' }">
			<a-button size="small" type="primary" shape="circle">
				<PlusOutlined />
			</a-button>
		</router-link>

		<a-input
			v-model:value="pesquisar"
			placeholder="Pesquisar"
			style="margin-top: 10px"
		>
		</a-input>
	</div>

	<div>
		<a-table
			:columns="columns"
			:data-source="dataVisivel"
			size="small"
			:loading="loading"
		>
			<template #bodyCell="{ column, record }">
				<template v-if="column.key === 'convidados'">
					<a-tooltip>
						<template #title>
							<div
								v-for="convidado in record.titular.convidados"
								:key="convidado.nome"
								style="
									display: flex;
									justify-content: space-between;
									margin-bottom: 5px;
								"
							>
								<b> {{ convidado.nome }} </b>

								<span v-if="convidado.crianca">
									(criança)
								</span>

								<CheckOutlined
									class="icone-confirmado"
									v-if="convidado.confirmado"
								/>

								<CloseOutlined
									class="icone-nao-confirmado"
									v-else
								/>
							</div>
						</template>

						<a-button type="link" size="small">
							<TeamOutlined />
						</a-button>
					</a-tooltip>
				</template>

				<template v-if="column.key === 'ver'">
					<a-button
						type="link"
						@click="editarConvite(record)"
						size="small"
					>
						<SearchOutlined />
					</a-button>
				</template>

				<template v-if="column.key === 'apagar'">
					<a-popconfirm
						title="Deseja realmente excluir este convite?"
						ok-text="Sim"
						cancel-text="Não"
						@confirm="excluirConvite(record)"
					>
						<a-button type="link" size="small">
							<DeleteOutlined />
						</a-button>
					</a-popconfirm>
				</template>
			</template>
		</a-table>
	</div>

	<a-modal
		v-model:visible="modalVisualizarAlterar"
		:title="conviteVisualizar.nome"
		width="95%"
		style="top: 10%"
	>
		<template #footer>
			<a-button @click="modalVisualizarAlterar = false" v-if="!editar">
				Voltar
			</a-button>

			<a-button @click="editar = false" v-else> Cancelar </a-button>

			<a-button type="primary" @click="editar = true" v-if="!editar">
				Editar
			</a-button>

			<a-button type="primary" @click="salvarAlteracao()" v-if="editar">
				Salvar
			</a-button>
		</template>

		<a-form :rules="rules" :model="conviteVisualizar" v-if="!editar">
			<a-form-item label="Nome" class="form-item" name="nome">
				<a-input v-model:value="conviteVisualizar.nome" disabled />
			</a-form-item>

			<a-form-item label="Senha" class="form-item" name="senha">
				<a-input v-model:value="conviteVisualizar.senha" disabled />
			</a-form-item>
		</a-form>

		<a-form :rules="rules" :model="conviteAlterar" v-else>
			<a-form-item label="Nome" class="form-item" name="nome">
				<a-input v-model:value="conviteAlterar.nome" />
			</a-form-item>

			<a-form-item label="Senha" class="form-item" name="senha">
				<a-input v-model:value="conviteAlterar.senha" />
			</a-form-item>
		</a-form>

		<div v-if="conviteVisualizar.titularId">
			Convidados:

			<ul>
				<li
					v-for="convidado in conviteVisualizar.titular.convidados"
					:key="convidado.nome"
				>
					<b> {{ convidado.nome }} </b>

					<span v-if="convidado.crianca"> (criança) </span>

					<CheckOutlined
						class="icone-confirmado"
						v-if="convidado.confirmado"
					/>

					<CloseOutlined class="icone-nao-confirmado" v-else />
				</li>
			</ul>
		</div>
	</a-modal>
</template>

<script>
import {
	DeleteOutlined,
	EditOutlined,
	SearchOutlined,
	PlusOutlined,
	TeamOutlined,
	CheckOutlined,
	CloseOutlined,
} from "@ant-design/icons-vue";
import API from "@/api";

export default {
	components: {
		DeleteOutlined,
		EditOutlined,
		SearchOutlined,
		PlusOutlined,
		TeamOutlined,
		CheckOutlined,
		CloseOutlined,
	},
	data() {
		return {
			modalVisualizarAlterar: false,
			conviteVisualizar: {},
			conviteAlterar: {},
			pesquisar: "",
			data: [],
			dataVisivel: [],
			loading: false,
			editar: false,
			columns: [
				{
					title: "Nome",
					dataIndex: "nome",
					key: "nome",
				},
				{
					title: "",
					key: "convidados",
					width: "1px",
				},
				{
					title: "",
					key: "ver",
					width: "1px",
				},
				{
					title: "",
					key: "apagar",
					width: "1px",
				},
			],
			rules: {
				nome: [
					{
						required: true,
						message: "",
					},
				],
				senha: [
					{
						required: true,
						message: "",
					},
				],
				titularId: [
					{
						required: true,
						message: "",
					},
				],
			},
		};
	},

	watch: {
		pesquisar(value) {
			this.dataVisivel = this.data.filter((item) =>
				item.nome.toLowerCase().includes(value.toLowerCase())
			);
		},

		conviteVisualizar: {
			deep: true,
			handler() {},
		},
	},

	created() {
		this.consultarConvites();
	},

	methods: {
		editarConvite(convite) {
			this.conviteVisualizar = convite;
			this.conviteAlterar = convite;
			this.modalVisualizarAlterar = true;
		},

		excluirConvite(convite) {
			this.loading = true;

			API.removerConvite(convite.id)
				.then(() => {
					API.conviteRemovidoTitular(convite.titularId).then(() => {
						this.$message.success("Convite removido com sucesso");
						this.consultarConvites();
					});
				})
				.catch(() => {
					this.$message.error("Erro ao remover o convite");
				})
				.finally(() => {
					this.loading = false;
				});
		},

		salvarAlteracao() {
			this.loading = true;

			API.editarConvite(this.conviteAlterar)
				.then(() => {
					this.$message.success("Convite atualizado com sucesso");
					this.conviteAlterar = {};
					this.conviteVisualizar = {};
					this.pesquisar = "";
					this.editar = false;
					this.consultarConvites();
				})
				.catch((error) => {
					this.$message.error(error.response.data);
				})
				.finally(() => {
					this.loading = false;
					this.modalVisualizarAlterar = false;
				});
		},

		consultarConvites() {
			this.loading = true;

			API.listarConvites()
				.then((response) => {
					this.data = response.data;
					this.dataVisivel = response.data;
					this.loading = false;
				})
				.catch(() => {
					this.$message.error("Erro ao carregar os convites");
					this.loading = false;
				});
		},
	},
};
</script>

<style lang="less" scoped>
.icone-nao-confirmado {
	margin-left: 5px;
	color: red;
	margin-top: 5px;
}

.icone-confirmado {
	margin-left: 5px;
	color: green;
	margin-top: 5px;
}
</style>
