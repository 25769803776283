<template>
	<a-spin :spinning="loading">
		<template #indicator>
			<div
				style="
					display: flex;
					justify-content: center;
					align-items: center;
				"
			>
				<img
					src="@/assets/images/carregamento.gif"
					style="width: 150px; height: auto"
				/>
			</div>
		</template>

		<div id="area-presente" v-if="modoVisualizacao == 'mobile'">
			<div id="topo-pagina"></div>

			<div id="area-voltar">
				<a-page-header
					:onBack="() => $router.push({ name: 'presentes' })"
					sub-title="Voltar para lista de presentes"
				/>
			</div>

			<div id="area-imagem">
				<a-skeleton-image v-if="loading" />
				<img
					:src="presente.imagem"
					id="imagem-presente"
					alt="Imagem do presente"
					v-else
				/>
			</div>

			<a-skeleton-input
				style="width: 150px; margin-top: 10px"
				:active="true"
				size="small"
				v-if="loading"
			/>
			<h2 v-else>
				{{ presente.nome }}
			</h2>

			<div id="area-descricao">
				<a-skeleton
					:loading="true"
					active
					:paragraph="false"
					v-if="loading"
				>
				</a-skeleton>

				<a-typography-text type="secondary" v-else>
					{{ presente.descricao }}
				</a-typography-text>
			</div>

			<div id="area-preco" v-if="presente.nome != 'Pix'">
				<a-skeleton-input
					style="width: 100px; margin-top: 10px"
					:active="true"
					size="small"
					v-if="loading"
				/>

				<h3 v-else>
					{{ formatarValorParaReal(presente.preco) }}
				</h3>
			</div>

			<a-button
				block
				id="botao-pagamento"
				@click="pagarMercadoPago(presente)"
				v-if="presente.nome != 'Pix'"
			>
				Realizar Pagamento
			</a-button>

			<img
				src="@/assets/images/selo-compra.png"
				alt="Segurança"
				id="imagem-selo-compra"
				v-if="presente.nome != 'Pix'"
			/>

			<div v-if="presente.nome == 'Pix'" style="text-align: center">
				<p>Chave pix: <b> presente@jessicaedolglas.com.br </b></p>

				<img
					src="@/assets/images/qrcode-jd.png"
					alt="QR Code"
					style="width: 40%"
				/>
			</div>
		</div>
		<div v-else>
			<div id="area-desktop">
				<a-drawer
					placement="left"
					:visible="visible"
					@close="visible = false"
					class="desktop-menu"
					width="20%"
				>
					<template #closeIcon>
						<MenuOutlined
							id="icone-fechar-menu"
							class="texto-branco"
						/>
					</template>

					<template #extra>
						<a-button
							style="margin-right: 8px"
							@click="irParaAdmin()"
							v-if="usuarioAutenticado.perfil == 'ADMIN'"
						>
							Admin
						</a-button>
					</template>

					<nav id="menu-navegacao">
						<router-link
							:to="{ name: 'home' }"
							class="lista-item"
							@click="visible = false"
						>
							<div class="texto-branco lista-item font-custom">
								<span class="item-menu"> TELA INICIAL </span>
								<RightOutlined class="icone-menu" />
							</div>
						</router-link>

						<hr class="separador-menu-desktop" />

						<router-link
							:to="{ name: 'convite' }"
							class="lista-item"
							@click="visible = false"
						>
							<div class="texto-branco lista-item font-custom">
								<span class="item-menu"> O CONVITE </span>
								<RightOutlined class="icone-menu" />
							</div>
						</router-link>

						<hr class="separador-menu-desktop" />

						<router-link
							:to="{ name: 'detalhes' }"
							class="lista-item"
							@click="visible = false"
						>
							<div class="texto-branco lista-item font-custom">
								<span class="item-menu"> O CASAMENTO </span>
								<RightOutlined class="icone-menu" />
							</div>
						</router-link>

						<hr class="separador-menu-desktop" />

						<router-link
							:to="{ name: 'confirmacao' }"
							class="lista-item"
							@click="visible = false"
						>
							<div class="texto-branco lista-item font-custom">
								<span class="item-menu">
									CONFIRMAR PRESENÇA
								</span>
								<RightOutlined class="icone-menu" />
							</div>
						</router-link>

						<hr class="separador-menu-desktop" />

						<router-link
							:to="{ name: 'recados' }"
							class="lista-item"
							@click="visible = false"
						>
							<div class="texto-branco lista-item font-custom">
								<span class="item-menu">
									DEIXE SEU RECADO
								</span>
								<RightOutlined class="icone-menu" />
							</div>
						</router-link>

						<hr class="separador-menu-desktop" />

						<router-link
							:to="{ name: 'presentes' }"
							class="lista-item"
							@click="visible = false"
						>
							<div class="texto-branco lista-item-presente">
								DESEJA NOS PRESENTEAR ?
							</div>
						</router-link>

						<div @click="sair()" id="area-sair">
							<span class="texto-branco font-custom"> SAIR </span>
						</div>
					</nav>

					<div style="text-align: center">
						<img
							src="@/assets/images/logo-dj.png"
							:style="
								modoVisualizacao == 'mobile'
									? 'width: 80%'
									: 'width: 50%'
							"
						/>
					</div>
				</a-drawer>
				<a-affix id="area-botao-menu-desktop">
					<a-button
						shape="circle"
						:id="
							modoVisualizacao == 'mobile'
								? 'botao-menu'
								: 'botao-menu-desktop'
						"
						@click="visible = true"
					>
						<MenuOutlined />
					</a-button>

					<div id="barra-menu-desktop"></div>
				</a-affix>
			</div>

			<div id="area-voltar">
				<a-page-header
					:onBack="() => $router.push({ name: 'presentes' })"
					sub-title="Voltar para lista de presentes"
				/>
			</div>

			<a-row
				justify="center"
				align="center"
				:gutter="50"
				style="margin-top: 5%"
			>
				<a-col :span="4">
					<div>
						<a-skeleton-image v-if="loading" />
						<img
							:src="presente.imagem"
							alt="Imagem do presente"
							style="width: 100%"
							v-else
						/>
					</div>
				</a-col>

				<a-col :span="8">
					<a-skeleton-input
						style="width: 150px; margin-top: 10px"
						:active="true"
						size="small"
						v-if="loading"
					/>
					<h1 v-else>
						{{ presente.nome }}
					</h1>

					<div id="area-descricao-desktop">
						<a-skeleton
							:loading="true"
							active
							:paragraph="false"
							v-if="loading"
						>
						</a-skeleton>

						<a-typography-text type="secondary" v-else>
							{{ presente.descricao }}
						</a-typography-text>
					</div>

					<div id="area-preco" v-if="presente.nome != 'Pix'">
						<a-skeleton-input
							style="width: 100px; margin-top: 10px"
							:active="true"
							size="small"
							v-if="loading"
						/>

						<h2 v-else>
							{{ formatarValorParaReal(presente.preco) }}
						</h2>
					</div>

					<a-row
						type="flex"
						justify="start"
						align="start"
						v-if="presente.nome == 'Pix'"
					>
						<a-col :span="12">
							<img
								src="@/assets/images/pix-mercado-pago.png"
								alt="QR Code"
								style="width: 50%"
							/>
						</a-col>
					</a-row>
					<a-row
						type="flex"
						justify="start"
						align="start"
						v-if="presente.nome == 'Pix'"
					>
						<a-col :span="12">
							<p>
								Chave pix:
								<b style="font-size: 16px; line-height: 0">
									presente@jessicaedolglas.com.br
								</b>
							</p>
						</a-col>
					</a-row>

					<a-button
						block
						id="botao-pagamento"
						@click="pagarMercadoPago(presente)"
						v-if="presente.nome != 'Pix'"
					>
						Realizar Pagamento
					</a-button>

					<div id="imagem-selo-compra-desktop">
						<img
							src="@/assets/images/selo-compra-desktop.png"
							alt="Segurança"
							style="width: 90%"
							v-if="presente.nome != 'Pix'"
						/>
					</div>
				</a-col>
			</a-row>
		</div>
	</a-spin>
</template>

<script>
import API from "@/api";
import {
	LeftOutlined,
	MenuOutlined,
	RightOutlined,
} from "@ant-design/icons-vue";

export default {
	components: {
		LeftOutlined,
		MenuOutlined,
		RightOutlined,
	},

	data() {
		return {
			loading: false,
			visible: false,
			presente: {},
			windowHeight: 0,
			windowWidth: 0,
			modoVisualizacao: "",
		};
	},

	computed: {
		usuarioAutenticado() {
			return this.$store.getters.getUser;
		},
	},

	watch: {
		windowWidth(val) {
			this.modoVisualizacao =
				this.windowHeight > this.windowWidth ? "mobile" : "desktop";
		},
	},

	mounted() {
		this.consultarPresentePorId();
		this.windowHeight = window.innerHeight;
		this.windowWidth = window.innerWidth;
	},

	created() {
		window.addEventListener("resize", this.resizeHandler);
	},

	destroyed() {
		window.removeEventListener("resize", this.resizeHandler);
	},

	methods: {
		irParaAdmin() {
			this.$router.push({ name: "homeAdmin" });
		},

		sair() {
			this.$store.dispatch("logout");
			this.$router.push({ name: "entrar" });
		},

		resizeHandler(e) {
			this.windowHeight = window.innerHeight;
			this.windowWidth = window.innerWidth;
		},

		consultarPresentePorId() {
			this.loading = true;

			API.consultarPresentePorId(this.$route.params.id).then(
				(response) => {
					this.presente = response.data;

					this.loading = false;
				}
			);
		},

		formatarValorParaReal(val) {
			return `R$ ${val?.toFixed(2)?.replace(".", ",")}`;
		},

		pagarMercadoPago(presente) {
			this.salvarLog(presente);

			window.location.href = presente.link;
		},

		salvarLog(presente) {
			let log = {
				conviteId: this.usuarioAutenticado.id,
				presnteId: presente.id,
				acao: "CLICOU EM PAGAR",
			};

			console.log(log);

			API.SalvarLogPresente(log).then((response) => {});
		},
	},
};
</script>

<style lang="less" scoped>
#area-presente {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

#topo-pagina {
	height: 60px;
	background-color: #c2a6c1;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-left: 20px;
}

#area-descricao {
	width: 90%;
	text-align: center;
}

#area-descricao-mobile {
	width: 90%;
	text-align: left;
}

#area-descricao-desktop {
	text-align: left;
	margin-top: -20px;
}

#imagem-presente {
	border: 1px solid #e6e6e6;
	border-radius: 10px;
}

#area-imagem {
	margin-top: 20px;
}

#botao-pagamento {
	width: 90%;
	margin-top: 20px;
	background-color: #c2a6c1;
	color: #fff;
	border-color: #c2a6c1;
}

#imagem-selo-compra {
	width: 70%;
	margin-top: 20px;
}

#imagem-selo-compra-desktop {
	text-align: center;
	width: 90%;
}

#area-voltar {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	margin: -5px 0 -20px 0;
}

.lista-menu {
	list-style-type: none;
	text-align: right;
}

.separador-menu {
	border: none;
	border-top: 2px dotted #fff;
	width: 100%;
}

.lista-item {
	width: 100%;
	font-weight: bolder;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	.item-menu {
		font-size: 1.3em;
		width: 70%;
		line-height: 1em;
		cursor: pointer;
		padding: 10px 20px;
	}

	.icone-menu {
		color: #6b604e;
		font-weight: bolder;
	}
}

.lista-item-presente {
	padding-top: 10px;
	font-size: 10px;
	font-weight: 400;
	letter-spacing: 1px;
}

#logo-fim-menu {
	position: fixed;
	bottom: 30px;
}

#icone-fechar-menu {
	margin-top: 10px;
	font-size: 2em;
	font-weight: bolder;
}

#area-sair {
	padding-top: 20px;
	cursor: pointer;
	user-select: none;
	font-size: 2em;
}

#titulo-pagina-desktop {
	margin-top: 10px;
	margin-left: 10px;

	#titulo-1 {
		font-size: 17px;
		line-height: 0px;
		color: #fff;
		letter-spacing: 1.5px;
	}

	#titulo-2 {
		font-size: 42px;
		line-height: 50px;
		font-weight: bold;
		color: #fff;
	}
}
</style>
