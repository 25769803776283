<template>
	<div v-if="modoVisualizacao == 'desktop'">
		<a-drawer
			placement="left"
			:visible="visible"
			@close="visible = false"
			class="desktop-menu"
			width="20%"
		>
			<template #closeIcon>
				<MenuOutlined id="icone-fechar-menu" class="texto-branco" />
			</template>

			<template #extra>
				<a-button
					style="margin-right: 8px"
					@click="irParaAdmin()"
					v-if="usuarioAutenticado.perfil == 'ADMIN'"
				>
					Admin
				</a-button>
			</template>

			<nav id="menu-navegacao">
				<router-link
					:to="{ name: 'home' }"
					class="lista-item"
					@click="visible = false"
				>
					<div class="texto-branco lista-item font-custom">
						<span class="item-menu"> TELA INICIAL </span>
						<RightOutlined class="icone-menu" />
					</div>
				</router-link>

				<hr class="separador-menu-desktop" />

				<router-link
					:to="{ name: 'convite' }"
					class="lista-item"
					@click="visible = false"
				>
					<div class="texto-branco lista-item font-custom">
						<span class="item-menu"> O CONVITE </span>
						<RightOutlined class="icone-menu" />
					</div>
				</router-link>

				<hr class="separador-menu-desktop" />

				<router-link
					:to="{ name: 'detalhes' }"
					class="lista-item"
					@click="visible = false"
				>
					<div class="texto-branco lista-item font-custom">
						<span class="item-menu"> O CASAMENTO </span>
						<RightOutlined class="icone-menu" />
					</div>
				</router-link>

				<hr class="separador-menu-desktop" />

				<router-link
					:to="{ name: 'confirmacao' }"
					class="lista-item"
					@click="visible = false"
				>
					<div class="texto-branco lista-item font-custom">
						<span class="item-menu"> CONFIRMAR PRESENÇA </span>
						<RightOutlined class="icone-menu" />
					</div>
				</router-link>

				<hr class="separador-menu-desktop" />

				<router-link
					:to="{ name: 'recados' }"
					class="lista-item"
					@click="visible = false"
				>
					<div class="texto-branco lista-item font-custom">
						<span class="item-menu"> DEIXE SEU RECADO </span>
						<RightOutlined class="icone-menu" />
					</div>
				</router-link>

				<hr class="separador-menu-desktop" />

				<router-link
					:to="{ name: 'presentes' }"
					class="lista-item"
					@click="visible = false"
				>
					<div class="texto-branco lista-item-presente">
						DESEJA NOS PRESENTEAR ?
					</div>
				</router-link>

				<div @click="sair()" id="area-sair">
					<span class="texto-branco font-custom"> SAIR </span>
				</div>
			</nav>

			<div style="text-align: center">
				<img src="@/assets/images/logo-dj.png" style="width: 50%" />
			</div>
		</a-drawer>
		<a-affix id="area-botao-menu-desktop-layout">
			<a-button
				shape="circle"
				id="botao-menu-desktop"
				@click="visible = true"
			>
				<MenuOutlined />
			</a-button>

			<div id="barra-menu-desktop"></div>
		</a-affix>
	</div>

	<div v-if="modoVisualizacao == 'mobile'">
		<img src="@/assets/images/background-2.png" class="imagem-fundo" />

		<div id="area-convite">
			<img src="@/assets/images/convite.png" id="convite" />
		</div>
	</div>
	<div v-else>
		<img src="@/assets/images/fundo-desktop.png" class="imagem-fundo" />
		<div id="area-convite-desktop">
			<img
				src="@/assets/images/convite-desktop.png"
				id="convite-desktop"
			/>
		</div>
	</div>
</template>

<script>
import { MenuOutlined, RightOutlined } from "@ant-design/icons-vue";
import Menu from "@/components/MenuComponent.vue";

export default {
	components: {
		MenuOutlined,
		RightOutlined,
		Menu,
	},
	data() {
		return {
			modoVisualizacao: "",
			visible: false,
			windowHeight: 0,
			windowWidth: 0,
			loading: true,
		};
	},

	computed: {
		nameRouter() {
			return this.$route.name;
		},

		usuarioAutenticado() {
			return this.$store.getters.getUser;
		},
	},

	created() {
		window.addEventListener("resize", this.resizeHandler);
	},

	destroyed() {
		window.removeEventListener("resize", this.resizeHandler);
	},

	mounted() {
		this.windowHeight = window.innerHeight;
		this.windowWidth = window.innerWidth;

		setTimeout(() => {
			this.loading = false;
		}, 1500);
	},

	watch: {
		windowWidth(val) {
			this.modoVisualizacao =
				this.windowHeight > this.windowWidth ? "mobile" : "desktop";
		},
	},

	methods: {
		irParaAdmin() {
			this.$router.push({ name: "homeAdmin" });
		},

		sair() {
			this.$store.dispatch("logout");
			this.$router.push({ name: "entrar" });
		},

		resizeHandler(e) {
			this.windowHeight = window.innerHeight;
			this.windowWidth = window.innerWidth;
		},

		tituloPagina() {
			switch (this.$route.name) {
				case "home":
					return ["", ""];
				case "convite":
					return ["NOSSO", "CONVITE"];
				case "recados":
					return ["DEIXE SEU", "RECADO"];
				case "confirmacao":
					return ["CONFIRME", "PRESENÇA"];
				case "presentes":
					return ["LISTA DE", "PRESENTES"];
				case "confirmarPresenca":
					return ["CONFIRMAR", "PRESENÇA"];
				default:
					return ["", ""];
			}
		},
	},
};
</script>

<style lang="less" scoped>
#conteudo-pagina {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 10%;
}

#area-convite {
	position: absolute;
	top: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	z-index: -1;
}

#area-convite-desktop {
	position: absolute;
	top: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	z-index: -1;
}

#convite {
	width: 90%;
	box-shadow: 0 12px 14px -5px rgba(0, 0, 0, 0.83);
	-webkit-box-shadow: 0 12px 14px -5px rgba(0, 0, 0, 0.83);
	-moz-box-shadow: 0 12px 14px -5px rgba(0, 0, 0, 0.83);
}

#convite-desktop {
	width: 80%;
	box-shadow: 0 12px 14px -5px rgba(0, 0, 0, 0.83);
	-webkit-box-shadow: 0 12px 14px -5px rgba(0, 0, 0, 0.83);
	-moz-box-shadow: 0 12px 14px -5px rgba(0, 0, 0, 0.83);
}

.lista-item {
	width: 100%;
	font-weight: bolder;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	.item-menu {
		font-size: 1.3em;
		width: 70%;
		line-height: 1em;
		cursor: pointer;
		padding: 10px 20px;
	}

	.icone-menu {
		color: #6b604e;
		font-weight: bolder;
	}
}

#area-sair {
	padding-top: 20px;
	cursor: pointer;
	user-select: none;
	font-size: 2em;
}

.separador-menu-desktop {
	border: none;
}

.lista-item-presente {
	padding-top: 10px;
	font-size: 10px;
	font-weight: 400;
	letter-spacing: 1px;
}

#titulo-pagina-desktop {
	margin-top: -60px;
	margin-left: 100px;

	#titulo-1 {
		font-size: 17px;
		line-height: 0px;
		color: #fff;
		letter-spacing: 1.5px;
	}

	#titulo-2 {
		font-size: 42px;
		line-height: 50px;
		font-weight: bold;
		color: #fff;
	}
}
</style>
