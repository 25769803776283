import { createApp } from "vue";
import Antd from "ant-design-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//import vueAwesomeSidebar from "vue-awesome-sidebar";

//import "vue-awesome-sidebar/dist/vue-awesome-sidebar.css";
import "@/assets/styles/style.less";

const app = createApp(App);

app.use(Antd).use(router).use(store).mount("#app");
