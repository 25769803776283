<template>
	<div id="conteudo-layout">
		<div>
			<div>
				<a-drawer
					placement="left"
					:visible="visible"
					@close="visible = false"
					:class="
						modoVisualizacao == 'mobile' ? 'menu' : 'desktop-menu'
					"
					:width="modoVisualizacao == 'mobile' ? '60%' : '20%'"
				>
					<template #closeIcon>
						<MenuOutlined
							id="icone-fechar-menu"
							class="texto-branco"
						/>
					</template>

					<template #extra>
						<a-button
							style="margin-right: 8px"
							@click="irParaAdmin()"
							v-if="usuarioAutenticado.perfil == 'ADMIN'"
						>
							Admin
						</a-button>
					</template>

					<nav id="menu-navegacao">
						<router-link
							:to="{ name: 'home' }"
							class="lista-item"
							@click="visible = false"
						>
							<div class="texto-branco lista-item font-custom">
								<span class="item-menu"> TELA INICIAL </span>
								<RightOutlined class="icone-menu" />
							</div>
						</router-link>

						<hr
							:class="
								modoVisualizacao == 'mobile'
									? 'separador-menu'
									: 'separador-menu-desktop'
							"
						/>

						<router-link
							:to="{ name: 'convite' }"
							class="lista-item"
							@click="visible = false"
						>
							<div class="texto-branco lista-item font-custom">
								<span class="item-menu"> O CONVITE </span>
								<RightOutlined class="icone-menu" />
							</div>
						</router-link>

						<hr
							:class="
								modoVisualizacao == 'mobile'
									? 'separador-menu'
									: 'separador-menu-desktop'
							"
						/>

						<router-link
							:to="{ name: 'detalhes' }"
							class="lista-item"
							@click="visible = false"
						>
							<div class="texto-branco lista-item font-custom">
								<span class="item-menu"> O CASAMENTO </span>
								<RightOutlined class="icone-menu" />
							</div>
						</router-link>

						<hr
							:class="
								modoVisualizacao == 'mobile'
									? 'separador-menu'
									: 'separador-menu-desktop'
							"
						/>

						<router-link
							:to="{ name: 'confirmacao' }"
							class="lista-item"
							@click="visible = false"
						>
							<div class="texto-branco lista-item font-custom">
								<span class="item-menu">
									CONFIRMAR PRESENÇA
								</span>
								<RightOutlined class="icone-menu" />
							</div>
						</router-link>

						<hr
							:class="
								modoVisualizacao == 'mobile'
									? 'separador-menu'
									: 'separador-menu-desktop'
							"
						/>

						<router-link
							:to="{ name: 'recados' }"
							class="lista-item"
							@click="visible = false"
						>
							<div class="texto-branco lista-item font-custom">
								<span class="item-menu">
									DEIXE SEU RECADO
								</span>
								<RightOutlined class="icone-menu" />
							</div>
						</router-link>

						<hr
							:class="
								modoVisualizacao == 'mobile'
									? 'separador-menu'
									: 'separador-menu-desktop'
							"
						/>

						<router-link
							:to="{ name: 'presentes' }"
							class="lista-item"
							@click="visible = false"
						>
							<div class="texto-branco lista-item-presente">
								DESEJA NOS PRESENTEAR ?
							</div>
						</router-link>

						<div @click="sair()" id="area-sair">
							<span class="texto-branco font-custom"> SAIR </span>
						</div>
					</nav>

					<div style="text-align: center">
						<img
							src="@/assets/images/logo-dj.png"
							:style="
								modoVisualizacao == 'mobile'
									? 'width: 80%'
									: 'width: 50%'
							"
						/>
					</div>
				</a-drawer>
				<a-affix
					:id="
						modoVisualizacao == 'mobile'
							? 'area-botao-menu'
							: 'area-botao-menu-desktop-layout'
					"
					v-if="
						nameRouter != 'presentes' &&
						modoVisualizacao == 'mobile'
					"
				>
					<a-tooltip
						v-model:visible="ajudaMenu"
						title="Use o menu para navegar pelo convite"
						placement="right"
						overlayClassName="tooltip"
						color="#fff"
						trigger="contextmenu"
					>
						<a-button
							shape="circle"
							:id="
								modoVisualizacao == 'mobile'
									? 'botao-menu'
									: 'botao-menu-desktop'
							"
							@click="visible = true"
						>
							<MenuOutlined />
						</a-button>
					</a-tooltip>

					<div
						:id="
							modoVisualizacao == 'mobile'
								? 'barra-menu'
								: 'barra-menu-desktop'
						"
					></div>

					<div
						:id="
							modoVisualizacao == 'mobile'
								? 'titulo-pagina'
								: 'titulo-pagina-desktop'
						"
					>
						<div id="titulo-1" class="font-montserrat">
							{{ tituloPagina()[0] }}
						</div>
						<div id="titulo-2">{{ tituloPagina()[1] }}</div>
					</div>
				</a-affix>
			</div>

			<div>
				<router-view />
			</div>
		</div>
	</div>
</template>

<script>
import { MenuOutlined, RightOutlined } from "@ant-design/icons-vue";
import Menu from "@/components/MenuComponent.vue";

export default {
	components: {
		MenuOutlined,
		RightOutlined,
		Menu,
	},
	data() {
		return {
			ajudaMenu: true,
			visible: false,
			windowHeight: 0,
			windowWidth: 0,
			loading: true,
			modoVisualizacao: "",
		};
	},

	computed: {
		nameRouter() {
			return this.$route.name;
		},

		usuarioAutenticado() {
			return this.$store.getters.getUser;
		},
	},

	created() {
		window.addEventListener("resize", this.resizeHandler);
	},

	destroyed() {
		window.removeEventListener("resize", this.resizeHandler);
	},

	mounted() {
		this.windowHeight = window.innerHeight;
		this.windowWidth = window.innerWidth;

		setTimeout(() => {
			this.loading = false;
		}, 1500);
	},

	watch: {
		windowWidth(val) {
			this.modoVisualizacao =
				this.windowHeight > this.windowWidth ? "mobile" : "desktop";
		},
	},

	methods: {
		resizeHandler(e) {
			this.windowHeight = window.innerHeight;
			this.windowWidth = window.innerWidth;
		},

		irParaAdmin() {
			this.$router.push({ name: "homeAdmin" });
		},

		sair() {
			this.$store.dispatch("logout");
			this.$router.push({ name: "entrar" });
		},

		tituloPagina() {
			switch (this.$route.name) {
				case "home":
					return ["", ""];
				case "detalhes":
					return ["CASAMENTO", "DETALHES"];
				case "recados":
					return ["DEIXE SEU", "RECADO"];
				case "confirmacao":
					return ["CONFIRME", "PRESENÇA"];
				case "presentes":
					return ["LISTA DE", "PRESENTES"];
				case "confirmarPresenca":
					return ["CONFIRMAR", "PRESENÇA"];
				default:
					return ["", ""];
			}
		},
	},
};
</script>

<style lang="less" scoped>
#conteudo-pagina {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 10px;
}

#titulo-pagina {
	margin-top: -40px;
	margin-left: 55px;

	#titulo-1 {
		padding-top: 3px;
		font-size: 13px;
		line-height: 0px;
		color: #000;
		letter-spacing: 1.5px;
	}

	#titulo-2 {
		font-size: 32px;
		line-height: 40px;
		font-weight: bold;
		color: #6b604e;
	}
}

#conteudo-layout {
	display: flex;
	flex-direction: column;
}

#content {
	background-image: url("@/assets/images/background.png");
	background-size: 100% 100%;
	flex: 1;
	background-repeat: no-repeat;
	background-attachment: fixed;
}

#content-2 {
	background-image: url("@/assets/images/background-2.png");
	background-size: 100% 100%;
	flex: 1;
	background-repeat: no-repeat;
	background-attachment: fixed;
}

#area-sair {
	padding-top: 20px;
	cursor: pointer;
	user-select: none;
	font-size: 2em;
}

.separador-menu {
	border: none;
	border-top: 2px dotted #fff;
	width: 100%;
}

.lista-item {
	width: 100%;
	font-weight: bolder;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	.item-menu {
		font-size: 1.3em;
		width: 70%;
		line-height: 1em;
		cursor: pointer;
		padding: 10px 20px;
	}

	.icone-menu {
		color: #6b604e;
		font-weight: bolder;
	}
}

.lista-item-presente {
	padding-top: 10px;
	font-size: 10px;
	font-weight: 400;
	letter-spacing: 1px;
}

#logo-fim-menu {
	position: fixed;
	bottom: 30px;
}

#icone-fechar-menu {
	margin-top: 10px;
	font-size: 2em;
	font-weight: bolder;
}
</style>
