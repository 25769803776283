<template>
	<div id="conteudo">
		<a-checkbox-group v-model:value="confirmados">
			<a-checkbox
				class="check-convidados"
				:value="convidados.titular.nome"
			>
				{{ convidados.titular.nome }}
				<a-tooltip
					title="Presença confirmada"
					v-if="convidados.titular.confirmado"
				>
					<check-circle-outlined />
				</a-tooltip>
			</a-checkbox>

			<div
				v-for="convidado in convidados.acompanhantes"
				:key="convidado.nome"
			>
				<a-checkbox :value="convidado.nome" class="check-convidados">
					{{ convidado.nome }}
					<a-tooltip
						title="Presença confirmada"
						v-if="convidado.confirmado"
					>
						<check-circle-outlined />
					</a-tooltip>
				</a-checkbox>
			</div>
		</a-checkbox-group>

		<div style="margin-top: 100px">
			<a-button size="small"> Editar </a-button>

			<a-button size="small" type="primary" style="margin-left: 5px">
				Confirmar
			</a-button>
		</div>
	</div>
</template>

<script>
import { CheckCircleOutlined } from "@ant-design/icons-vue";
import Menu from "@/components/MenuComponent.vue";

export default {
	components: {
		CheckCircleOutlined,
		Menu,
	},
	data() {
		return {
			windowHeight: 0,
			windowWidth: 0,
			convidados: {
				titular: {
					nome: "Dolglas Mesquita",
					confirmado: false,
				},
				acompanhantes: [
					{
						nome: "Jessica Pereira",
						confirmado: false,
					},
					{
						nome: "Francielly Fernanda",
						confirmado: false,
					},
					{
						nome: "Flávia Fernanda",
						confirmado: false,
					},
				],
			},
			confirmados: [],
		};
	},
	created() {
		window.addEventListener("resize", this.resizeHandler);
	},

	destroyed() {
		window.removeEventListener("resize", this.resizeHandler);
	},

	mounted() {
		this.windowHeight = window.innerHeight;
		this.windowWidth = window.innerWidth;
	},

	resizeHandler(e) {
		this.windowHeight = window.innerHeight;
		this.windowWidth = window.innerWidth;
	},
};
</script>

<style lang="less" scoped>
#conteudo {
	width: 100%;
	margin-top: 10%;
	height: 60vh;
	display: flex;
	flex-direction: column;
	//align-items: center;
	//justify-content: center;
	padding: 15px;
}

.check-convidados {
	font-size: 20px;
}

:deep(.ant-checkbox-checked .ant-checkbox-inner) {
	background-color: #f2c3dc;
	border-color: #a64dff;
}
</style>
