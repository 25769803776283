<template>
	<div v-if="modoVisualizacao == 'mobile'">
		<img
			src="@/assets/images/background-inicial.png"
			class="imagem-fundo"
		/>
		<div id="conteudo-pagina-entrar">
			<div id="content">
				<div id="pagina">
					<div class="imagem-centralizada">
						<img
							src="@/assets/images/foto-entrar.png"
							id="imagem-entrar"
						/>
					</div>

					<div class="imagem-centralizada">
						<img
							src="@/assets/images/nomes-entrar.png"
							id="imagem-nomes-entrar"
						/>
					</div>

					<div id="data" class="text-dark">
						08 DE NOVEMBRO DE 2024
					</div>

					<div
						id="botao-entrar"
						class="text-dark"
						@click="abrirModalEntrar()"
					>
						CLIQUE PARA ACESSAR
						<img src="@/assets/images/icons/mao.png" width="15" />
					</div>

					<div class="imagem-centralizada">
						<img
							src="@/assets/images/logo-aliancas.png"
							id="imagem-nomes-entrar"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div v-else>
		<img src="@/assets/images/entrar-desktop.png" class="imagem-fundo" />

		<div id="area-desktop">
			<img
				src="@/assets/images/jessica-dolglas-desktop.png"
				alt=""
				style="width: 40%"
			/>
			<div id="area-data" class="text-dark">08 DE NOVEMBRO DE 2024</div>

			<div
				id="botao-entrar-desktop"
				class="text-dark"
				@click="abrirModalEntrar()"
			>
				CLIQUE PARA ACESSAR
				<img src="@/assets/images/icons/mao.png" width="20" />
			</div>
		</div>
	</div>

	<a-modal
		v-model:visible="visible"
		title="Insira seu código de acesso"
		:closable="false"
		centered
		@ok="modal = false"
		:footer="null"
	>
		<div style="text-align: center">
			<a-form :model="codigoAcesso" @finish="entrar()">
				<a-input
					v-model:value="codigoAcesso"
					ref="codigo"
					style="
						width: 100%;
						border-radius: 6px;
						background-color: #f1e7f594;
						border: 1px solid #c2a6c1;
					"
					placeholder="XYZ2024"
					autocapitalize="characters"
				/>

				<div id="area-botoes-entrar">
					<a-button
						@click="visible = false"
						style="
							border-radius: 6px;
							border-color: #c2a6c1;
							color: #c2a6c1;
						"
					>
						Cancelar
					</a-button>

					<a-button
						html-type="submit"
						style="
							border-radius: 6px;
							background-color: #c2a6c1;
							border-color: #c2a6c1;
							color: #fff;
							margin-left: 10px;
						"
					>
						Entrar
					</a-button>
				</div>
			</a-form>
		</div>
	</a-modal>
</template>

<script>
import API from "@/api";

export default {
	components: {},
	data() {
		return {
			windowHeight: 0,
			windowWidth: 0,
			visible: false,
			codigoAcesso: "",
			modoVisualizacao: "",
		};
	},
	watch: {
		codigoAcesso(val) {
			this.codigoAcesso = val.toUpperCase();
		},
		windowWidth(val) {
			this.modoVisualizacao =
				this.windowHeight > this.windowWidth ? "mobile" : "desktop";
		},
	},

	created() {
		window.addEventListener("resize", this.resizeHandler);
	},

	destroyed() {
		window.removeEventListener("resize", this.resizeHandler);
	},

	mounted() {
		this.windowHeight = window.innerHeight;
		this.windowWidth = window.innerWidth;
	},

	methods: {
		entrar() {
			API.entrarConvite(this.codigoAcesso.trim())
				.then((response) => {
					if (response.data) {
						this.$store
							.dispatch("login", response.data)
							.then(() => {
								this.$router.push({
									name: "home",
								});
							});
					}
				})
				.catch((error) => {
					this.$message.error("Código de acesso inválido");
				});
		},

		abrirModalEntrar() {
			this.visible = true;

			setTimeout(() => {
				this.$refs.codigo.focus();
			}, 500);
		},

		resizeHandler(e) {
			this.windowHeight = window.innerHeight;
			this.windowWidth = window.innerWidth;
		},
	},
};
</script>

<style lang="less" scoped>
#conteudo-pagina-entrar {
	display: flex;
	flex-direction: column;
}

#pagina {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 17% 10px;
}

#imagem-entrar {
	width: 65%;
}

#imagem-nomes-entrar {
	width: 65%;
}

.imagem-centralizada {
	text-align: center;
}

#botao-entrar {
	border: 2px solid #c2a6c1;
	border-radius: 22px;
	padding: 5px 20px;
	margin: 10px 0;
	font-size: 13px;
	font-weight: 500;
	cursor: pointer;
	user-select: none;
}

#botao-entrar-desktop {
	border: 4px solid #c2a6c1;
	border-radius: 22px;
	padding: 10px 25px;
	margin: 15px 0;
	font-size: 14px;
	font-weight: 500;
	cursor: pointer;
	user-select: none;
}

#botao-entrar:hover {
	background-color: #f1e7f594;
}

#data {
	font-size: 11px;
	font-weight: 500;
}
#area-botoes-entrar {
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
}
#area-desktop {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 90vh;
}

#area-data {
	font-size: 18px;
	font-weight: 400;
	margin-top: 20px;
	color: #6b604e;
}
</style>
