div
<template>
	<div id="area">
		<a-page-header
			:onBack="() => $router.push({ name: 'convidadosAdmin' })"
			:title="'Convidados de ' + data.nome"
		/>
	</div>

	<a-button
		type="primary"
		@click="modalAdicionar = true"
		style="margin: 10px"
	>
		Adicionar Convidado
	</a-button>

	<a-table
		:columns="columns"
		:data-source="data.convidados"
		rowKey="id"
		:loading="loading"
	>
		<template #emptyText>
			<p>Nenhum convidado cadastrado</p>
		</template>

		<template #bodyCell="{ column, record }">
			<template v-if="column.key === 'crianca'">
				<span v-if="record.crianca"> Sim </span>
				<span v-else> Não </span>
			</template>

			<template v-if="column.key === 'confirmado'">
				<CheckOutlined v-if="record.confirmado" style="color: green" />
				<CloseOutlined v-else style="color: red" />
			</template>

			<template v-if="column.key === 'editar'">
				<a-button
					type="link"
					@click="editarConvidado(record)"
					size="small"
				>
					<EditOutlined />
				</a-button>
			</template>

			<template v-else-if="column.key === 'remover'">
				<a-popconfirm
					title="Deseja realmente remover este convidado?"
					ok-text="Sim"
					cancel-text="Não"
					@confirm="removerConvidado(record.id)"
				>
					<a-button type="link" size="small">
						<DeleteOutlined />
					</a-button>
				</a-popconfirm>
			</template>
		</template>
	</a-table>

	<a-modal
		v-model:visible="modalAdicionar"
		title="Adicionar Convidado"
		width="95%"
		@cancel="
			modalAdicionar = false;
			formState = { nome: '', crianca: false };
		"
	>
		<template #footer>
			<a-button @click="modalAdicionar = false">Cancelar</a-button>
			<a-button type="primary" @click="salvarConvidado(formState)">
				Salvar
			</a-button>
		</template>

		<a-form :model="formState" layout="vertical">
			<a-form-item label="Nome">
				<a-input v-model:value="formState.nome" />
			</a-form-item>

			<a-form-item label="Criança">
				<a-switch v-model:checked="formState.crianca" />
			</a-form-item>
		</a-form>
	</a-modal>

	<a-modal
		v-model:visible="modalEditar"
		:title="'Alterar Convidado ' + convidadoAlterar.nome"
		width="95%"
		@cancel="
			modalEditar = false;
			convidadoAlterar = { nome: '', crianca: false, id: 0 };
		"
	>
		<template #footer>
			<a-button @click="modalEditar = false"> Cancelar </a-button>
			<a-button type="primary" @click="salvarEdicao()"> Salvar </a-button>
		</template>

		<a-form :model="convidadoAlterar" layout="vertical">
			<a-form-item label="Nome">
				<a-input v-model:value="convidadoAlterar.nome" />
			</a-form-item>

			<a-form-item label="Criança">
				<a-switch v-model:checked="convidadoAlterar.crianca" />
			</a-form-item>
		</a-form>
	</a-modal>
</template>

<script>
import API from "@/api";
import {
	DeleteOutlined,
	EditOutlined,
	SaveOutlined,
	PlusOutlined,
	CheckOutlined,
	CloseOutlined,
} from "@ant-design/icons-vue";

export default {
	components: {
		DeleteOutlined,
		EditOutlined,
		SaveOutlined,
		PlusOutlined,
		CheckOutlined,
		CloseOutlined,
	},

	data() {
		return {
			modalAdicionar: false,
			modalEditar: false,
			loading: false,
			data: [],
			formState: {
				nome: "",
				crianca: false,
			},
			convidadoAlterar: {
				id: 0,
				nome: "",
				crianca: false,
			},
			columns: [
				{
					title: "Nome",
					dataIndex: "nome",
					key: "nome",
				},
				{
					title: "Criança",
					dataIndex: "crianca",
					key: "crianca",
					align: "center",
				},
				{
					title: "OK",
					dataIndex: "confirmado",
					key: "confirmado",
					align: "center",
				},
				{
					title: "",
					dataIndex: "editar",
					key: "editar",
					align: "center",
				},
				{
					title: "",
					dataIndex: "remover",
					key: "remover",
					align: "center",
				},
			],
		};
	},

	watch: {},

	mounted() {
		this.consultarTitularPorId();
	},

	methods: {
		consultarTitularPorId() {
			this.loading = true;

			API.titularPorId(this.$route.params.id).then((response) => {
				this.data = response.data;

				this.loading = false;
			});
		},

		editarConvidado(convidado) {
			this.modalEditar = true;
			this.convidadoAlterar.nome = convidado.nome;
			this.convidadoAlterar.crianca = convidado.crianca;
			this.convidadoAlterar.id = convidado.id;
		},

		salvarEdicao() {
			this.loading = true;

			const convidado = {
				id: this.convidadoAlterar.id,
				nome: this.convidadoAlterar.nome,
				crianca: this.convidadoAlterar.crianca,
				titularId: this.$route.params.id,
			};

			API.editarConvidado(convidado).then((response) => {
				this.loading = false;
				this.modalEditar = false;
				this.convidadoAlterar = { nome: "", crianca: false, id: 0 };

				this.$message.success("Convidado editado com sucesso");

				this.consultarTitularPorId();
			});
		},

		removerConvidado(idConvidado) {
			this.loading = true;

			API.excluirConvidado(idConvidado).then((response) => {
				this.loading = false;

				this.$message.success("Convidado removido com sucesso");

				this.consultarTitularPorId();
			});
		},

		salvarConvidado(value) {
			this.loading = true;

			const convidado = {
				nome: value.nome,
				crianca: value.crianca,
				idTitular: this.$route.params.id,
			};

			API.adicionarConvidado(convidado).then((response) => {
				this.loading = false;
				this.modalAdicionar = false;
				this.formState = {
					nome: "",
					crianca: false,
				};

				this.$message.success("Convidado adicionado com sucesso");

				this.consultarTitularPorId();
			});
		},
	},
};
</script>

<style lang="less" scoped>
#area {
	padding: 10px;
}

.imagem-presente {
	object-fit: cover;
	height: 150px;
}

.ant-upload-select-picture-card i {
	font-size: 32px;
	color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
	margin-top: 8px;
	color: #666;
}

:deep(.ant-upload-list-picture-card .ant-upload-list-item-error) {
	border: none !important;
}

.form-item {
	margin-top: -20px;
}
</style>
