export default ({ instance }) => {
	return {
		adicionarPresente(presente) {
			return instance({
				method: "post",
				url: "presente",
				data: presente,
			});
		},

		consultarPresentes() {
			return instance({
				method: "get",
				url: "presente",
			});
		},

		consultarPresentesDisponiveis() {
			return instance({
				method: "get",
				url: "presente/disponivel",
			});
		},

		consultarPresentePorId(id) {
			return instance({
				method: "get",
				url: "presente/" + id,
			});
		},

		editarPresente(presente) {
			return instance({
				method: "put",
				url: "presente",
				data: presente,
			});
		},

		excluirPresente(id) {
			return instance({
				method: "delete",
				url: "presente/" + id,
			});
		},

		quantidadePresentes() {
			return instance({
				method: "get",
				url: "presente/quantidade-presentes",
			});
		},

		SalvarLogPresente(log) {
			return instance({
				method: "post",
				url: "logpresentes",
				data: log,
			});
		},
	};
};
