<template>
	<div class="titulo-paginas">Olá, {{ usuarioLogado }}!</div>
	<a-row justify="center" id="conteudo-pagina">
		<a-col :span="12">
			<a-spin :spinning="loadingConvites">
				<router-link :to="{ name: 'conviteAdmin' }">
					<a-statistic
						title="Convites Criados"
						:value="qtdConvites"
						class="card-volumes"
					/>
				</router-link>
			</a-spin>
		</a-col>

		<a-col :span="12">
			<a-spin
				:spinning="
					loadingConvidadoConfirmadoAdultos &&
					loadingConvidadoConfirmadoCriancas
				"
			>
				<router-link
					:to="{
						name: 'convidadosAdmin',
					}"
				>
					<a-statistic
						title="Convidados Adultos/Crianças"
						:value="qtdConvidadosAdultos"
						class="card-volumes"
					>
						<template #suffix>
							/ {{ qtdConvidadosCriancas }}
						</template>
					</a-statistic>
				</router-link>
			</a-spin>
		</a-col>
	</a-row>

	<a-row justify="center" id="conteudo-pagina">
		<a-col :span="12">
			<a-spin
				:spinning="
					loadingConvidadoConfirmadoAdultos &&
					loadingConvidadoConfirmadoCriancas
				"
			>
				<router-link
					:to="{
						name: 'confirmadosAdmin',
					}"
				>
					<a-statistic
						title="Confirmados Adultos/Crianças"
						:value="qtdConvidadosConfirmadosAdultos"
						class="card-volumes"
					>
						<template #suffix>
							/ {{ qtdConvidadosConfirmadosCriancas }}
						</template>
					</a-statistic>
				</router-link>
			</a-spin>
		</a-col>

		<a-col :span="12">
			<a-spin :spinning="loadingPresente">
				<router-link :to="{ name: 'presentesAdmin' }">
					<a-statistic
						title="Presentes Cadastrados"
						:value="qtdPresentes"
						class="card-volumes"
					/>
				</router-link>
			</a-spin>
		</a-col>

		<a-col :span="12">
			<a-spin :spinning="loadingPresente">
				<router-link
					:to="{
						name: 'recadosAdmin',
						params: { status: 'naoAprovados' },
					}"
				>
					<a-statistic
						title="Recados Pendentes"
						:value="qtdRecadosNaoAprovados"
						class="card-volumes"
					/>
				</router-link>
			</a-spin>
		</a-col>

		<a-col :span="12">
			<a-spin :spinning="loadingPresente">
				<a-statistic title="Outro" :value="0" class="card-volumes" />
			</a-spin>
		</a-col>
	</a-row>
</template>

<script>
import API from "@/api";

export default {
	data() {
		return {
			current: ["inicio"],
			qtdConvites: 0,
			qtdPresentes: 0,
			qtdRecadosNaoAprovados: 0,
			loadingPresente: false,
			qtdConvidadosAdultos: 0,
			qtdConvidadosCriancas: 0,
			qtdConvidadosConfirmadosAdultos: 0,
			qtdConvidadosConfirmadosCriancas: 0,
			loadingConvidadoAdultos: false,
			loadingConvidadoCriancas: false,
			loadingRecadosNaoAprovados: false,
			loadingConvidadoConfirmadoAdultos: false,
			loadingConvidadoConfirmadoCriancas: false,
			loadingConvites: false,
		};
	},
	computed: {
		usuarioLogado() {
			return this.$store.getters.getAdmNome;
		},
	},

	mounted() {
		this.consultarPresentes();
		this.consultarQuantidadeConvidadosAdultos();
		this.consultarQuantidadeConvidadosCriancas();
		this.consultarQuantidadeConvites();
		this.consultarQuantidadeConvidadosConfirmadosAdultos();
		this.consultarQuantidadeConvidadosConfirmadosCriancas();
		this.consultarQuantidadeRecadosNaoAprovados();
	},
	methods: {
		sair() {
			this.$store.dispatch("logoutAdm");
			this.$router.push({ name: "loginAdministrativo" });
		},

		consultarPresentes() {
			this.loadingPresente = true;

			API.quantidadePresentes()
				.then((response) => {
					this.qtdPresentes = response.data;
				})
				.catch((error) => {})
				.finally(() => {
					this.loadingPresente = false;
				});
		},

		consultarQuantidadeConvidadosAdultos() {
			this.loadingConvidadoAdultos = true;

			API.quantidadeConvidadosAdultosTotal()
				.then((response) => {
					this.qtdConvidadosAdultos = response.data;
				})
				.catch((error) => {})
				.finally(() => {
					this.loadingConvidadoAdultos = false;
				});
		},

		consultarQuantidadeConvidadosCriancas() {
			this.loadingConvidadoCriancas = true;

			API.quantidadeConvidadosCriancasTotal()
				.then((response) => {
					this.qtdConvidadosCriancas = response.data;
				})
				.catch((error) => {})
				.finally(() => {
					this.loadingConvidadoCriancas = false;
				});
		},

		consultarQuantidadeConvites() {
			this.loadingConvites = true;

			API.quantidadeConvites()
				.then((response) => {
					this.qtdConvites = response.data;
				})
				.catch((error) => {})
				.finally(() => {
					this.loadingConvites = false;
				});
		},

		consultarQuantidadeConvidadosConfirmadosAdultos() {
			this.loadingConvidadoConfirmadoAdultos = true;

			API.quantidadeConvidadosAdultosConfirmados()
				.then((response) => {
					this.qtdConvidadosConfirmadosAdultos = response.data;
				})
				.catch((error) => {})
				.finally(() => {
					this.loadingConvidadoConfirmadoAdultos = false;
				});
		},

		consultarQuantidadeConvidadosConfirmadosCriancas() {
			this.loadingConvidadoConfirmadoCriancas = true;

			API.quantidadeConvidadosCriancasConfirmados()
				.then((response) => {
					this.qtdConvidadosConfirmadosCriancas = response.data;
				})
				.catch((error) => {})
				.finally(() => {
					this.loadingConvidadoConfirmadoCriancas = false;
				});
		},

		consultarQuantidadeRecadosNaoAprovados() {
			this.loadingRecadosNaoAprovados = true;

			API.listarRecados()
				.then((response) => {
					this.qtdRecadosNaoAprovados = response.data.filter(
						(recado) => !recado.aprovado
					).length;
				})

				.finally(() => {
					this.loadingRecadosNaoAprovados = false;
				});
		},
	},
};
</script>

<style lang="less" scoped>
#conteudo-pagina {
	padding: 0 10px;
}

.card-volumes {
	border: 1px solid #c3c3c3;
	min-height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin: 5px;
	padding: 5px;
	border-radius: 6px;
}

.card-volumes:hover {
	box-shadow: 0 0 10px 0 #c3c3c3;
	cursor: pointer;
}
</style>
