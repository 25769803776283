export default ({ instance }) => {
	return {
		consultarCategoriasPresente() {
			return instance({
				method: "get",
				url: "categoriaPresente",
			});
		},
	};
};
