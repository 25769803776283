<template>
	<CarOutlined v-if="icone.toUpperCase() == 'AVIAO'" />
	<HomeOutlined v-if="icone.toUpperCase() == 'CASA'" />
	<BulbOutlined v-if="icone.toUpperCase() == 'LAMPADA'" />
</template>

<script>
import {
	AppstoreOutlined,
	CarOutlined,
	HomeOutlined,
	BulbOutlined,
} from "@ant-design/icons-vue";

export default {
	components: {
		AppstoreOutlined,
		CarOutlined,
		HomeOutlined,
		BulbOutlined,
	},

	props: ["icone"],
};
</script>

<style></style>
