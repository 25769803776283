<template>
	<a-page-header
		:onBack="() => $router.push({ name: 'conviteAdmin' })"
		title="Criar Convite"
	/>

	<div id="area">
		<a-form :rules="rules" :model="formState" @finish="salvarConvite">
			<a-form-item label="Nome" class="form-item" name="nome">
				<a-input v-model:value="formState.nome" />
			</a-form-item>

			<a-form-item label="Senha" class="form-item" name="senha">
				<a-input v-model:value="formState.senha" />
			</a-form-item>

			<a-form-item label="Titular" class="form-item" name="titularId">
				<a-select
					v-model:value="formState.titularId"
					placeholder="Selecione um titular para o convite"
				>
					<template v-for="titular in titulares" :key="titular.id">
						<a-select-option :value="titular.id">
							{{ titular.nome }}
						</a-select-option>
					</template>
				</a-select>
			</a-form-item>

			<div v-if="formState.titularId">
				Convidados que serão adicionados ao convite:

				<ul>
					<li
						v-for="convidado in titulares.find(
							(t) => t.id == formState.titularId
						).convidados"
						:key="convidado"
					>
						<b> {{ convidado.nome }} </b>

						<span v-if="convidado.crianca"> (criança) </span>
					</li>
				</ul>
			</div>

			<a-row type="flex" justify="end">
				<a-form-item>
					<a-button
						type="primary"
						html-type="submit"
						:loading="loading"
					>
						Salvar
					</a-button>
				</a-form-item>
			</a-row>
		</a-form>
	</div>
</template>

<script>
import API from "@/api";
import { PlusOutlined } from "@ant-design/icons-vue";

export default {
	components: {
		PlusOutlined,
	},

	data() {
		return {
			loading: false,
			titulares: [],
			formState: {
				nome: "",
				senha: "",
				titularId: null,
			},
			rules: {
				nome: [
					{
						required: true,
						message: "",
					},
				],
				senha: [
					{
						required: true,
						message: "",
					},
				],
				titularId: [
					{
						required: true,
						message: "",
					},
				],
			},
		};
	},

	mounted() {
		this.listarTitulares();
	},

	watch: {
		"formState.senha"(val) {
			this.formState.senha = val.toUpperCase();
		},
	},

	methods: {
		salvarConvite() {
			this.loading = true;

			let convite = {
				nome: this.formState.nome,
				senha: this.formState.senha,
				titularId: this.formState.titularId,
				like: false,
			};

			API.adicionarConvite(convite)
				.then(() => {
					API.conviteCriadoTitular(convite.titularId)
						.then(() => {
							this.$message.success("Convite criado com sucesso");
							this.$router.push({ name: "conviteAdmin" });
						})
						.catch(() => {
							this.$message.error("Erro ao criar convite");
						});

					this.loading = false;
				})
				.catch((error) => {
					this.$message.error(error.response.data);
					this.loading = false;
				});
		},

		listarTitulares() {
			this.loading = true;

			API.listaTitulares().then((response) => {
				this.titulares = response.data.filter(
					(titular) => !titular.conviteCriado
				);
			});

			this.loading = false;
		},
	},
};
</script>

<style lang="less" scoped>
.form-item {
	margin-top: -20px;
}

#area {
	padding: 10px;
}

:deep(.ant-upload-list-picture-card .ant-upload-list-item-error) {
	border: none !important;
}
</style>
