<template>
	<a-row type="flex" justify="center" align="center">
		<a-col :span="23">
			<router-link
				:to="{
					name: 'homeAdmin',
				}"
			>
				<a-button type="primary" style="margin-bottom: 10px">
					Voltar
				</a-button>
			</router-link>

			<div id="area">
				<h1>Confirmados</h1>
			</div>

			<div>
				<a-radio-group
					v-model:value="filtro"
					option-type="button"
					button-style="solid"
					:options="opcoes"
				/>
			</div>

			<div>
				// Todos:
				<b> {{ data.length }} </b>

				// Adultos:
				<b>{{ data.filter((item) => !item.crianca).length }} </b>

				// Crianças:
				<b> {{ data.filter((item) => item.crianca).length }} </b>
			</div>

			<a-table
				:columns="columns"
				:data-source="dataVisivel"
				size="small"
				:loading="loading"
				rowKey="id"
			>
				<template #emptyText>
					<p>Nenhum Convidado Confirmado</p>
				</template>

				<template #bodyCell="{ column, record }">
					<template v-if="column.key === 'crianca'">
						<span v-if="record.crianca"> Sim </span>
					</template>
				</template>
			</a-table>
		</a-col>
	</a-row>
</template>

<script>
import {
	DeleteOutlined,
	EditOutlined,
	SearchOutlined,
	PlusOutlined,
	CheckOutlined,
	CloseOutlined,
} from "@ant-design/icons-vue";
import API from "@/api";

export default {
	components: {
		DeleteOutlined,
		EditOutlined,
		SearchOutlined,
		PlusOutlined,
		CheckOutlined,
		CloseOutlined,
	},
	data() {
		return {
			opcoes: [
				{ label: "Todos", value: "todos" },
				{ label: "Adultos", value: "adultos" },
				{ label: "Crianças", value: "criancas" },
			],
			filtro: "todos",
			modalAdicionar: false,
			modalEditar: false,
			formState: {
				nome: "",
			},
			titularAlterar: {
				id: 0,
				nome: "",
			},
			pesquisar: "",
			loading: false,
			data: [],
			dataVisivel: [],
			columns: [
				{
					title: "Nome",
					dataIndex: "nome",
					key: "nome",
				},
				{
					title: "Titular",
					dataIndex: "titular",
					key: "titular",
				},
				{
					title: "Crianca",
					dataIndex: "crianca",
					key: "crianca",
					align: "center",
				},
			],
		};
	},

	watch: {
		pesquisar(value) {
			this.dataVisivel = this.data.filter((item) =>
				item.nome.toLowerCase().includes(value.toLowerCase())
			);
		},

		filtro(value) {
			if (value === "todos") {
				this.dataVisivel = this.data;
			} else if (value === "adultos") {
				this.dataVisivel = this.data.filter((item) => !item.crianca);
			} else if (value === "criancas") {
				this.dataVisivel = this.data.filter((item) => item.crianca);
			}
		},
	},

	mounted() {
		this.listarConfirmados();
	},

	methods: {
		listarConfirmados() {
			this.loading = true;

			API.listaConvidadosConfirmados()
				.then((response) => {
					console.log(response.data);
					this.data = response.data;
					this.dataVisivel = response.data;
				})
				.catch((error) => {
					console.error(error);
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style lang="less" scoped></style>
